import { Component } from '@angular/core';
import { Navigation, Router  } from '@angular/router';
import { SharedDataService } from '~/app/membership/services/shared-data.service';

@Component({
  selector: 'app-scanner-details',
  templateUrl: './scanner-details.component.html',
  styleUrls: ['./scanner-details.component.scss']
})
export class ScannerDetailsComponent {
  qrCode: string | null = null;

  constructor(private router: Router,
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit(): void {
    this.qrCode = this.sharedDataService.getQrCode();
    console.log('QR Code received:', this.qrCode);  // Debugging: check if qrCode is received
  }
}
