import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  categoryType!: string;
  title!: string;
  buttons!: { label: string, type: string }[];
  selectedButton!: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.categoryType = this.route.snapshot.paramMap.get('type')!;
    this.setTitleAndButtons();
  }

  setTitleAndButtons(): void {
    switch (this.categoryType) {
      case 'classique':
        this.title = 'Parrainage CMU Classique';
        this.buttons = [
          { label: 'Parrainage(s) individuel(s)', type: 'primary' },
          { label: 'Parrainage Familial', type: 'secondary' }
        ];
        break;
      case 'eleve':
        this.title = 'Parrainage CMU Élèves';
        this.buttons = [
          { label: 'Packet de Base', type: 'primary' },
          { label: 'Packet Complémentaire', type: 'secondary' }
        ];
        break;
      case 'ndongo':
        this.title = 'Parrainage CMU Ndongo Daara';
        this.buttons = [
          { label: 'Packet de Base', type: 'primary' },
          { label: 'Packet Complémentaire', type: 'secondary' }
        ];
        break;
      default:
        this.title = 'Parrainage';
        this.buttons = [];
    }
    if (this.buttons.length > 0) {
      this.selectedButton = this.buttons[0].label;
      this.dataService.setSelectedButton(this.selectedButton);
    }
  }

  handleButtonClick(button: string): void {
    this.selectedButton = button;
    this.dataService.setSelectedButton(button);
  }
}
