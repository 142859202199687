import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Router} from "@angular/router";
import { Global } from '../constants/global.constant';
import { AuthService } from 'src/app/modules/auth/services/auth.service';

const LOCALIZATION_LOCAL_STORAGE_KEY = 'language';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  
    constructor(
        private router: Router,
        private authService: AuthService,
    ) { }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      
       let jwtToken = this.authService.getJwtToken();

        if (jwtToken) {
            request = request.clone({
                setHeaders: {
                  'Authorization': `Bearer ${jwtToken}`,
                  'Access-Control-Allow-Origin':'*',
                  'Accept-Language': <string>localStorage.getItem(Global.ACCEPT_LANGUAGE) || 'fr'
                }
            });
        }
        return next.handle(request);
    }
}
