import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SponsorshipService } from '../services/sponsorship.service';  
import { RegionModel } from '~/app/mutuels/models/regions.model';
import { RegionsService } from '~/app/mutuels/services/regions.service';

@Component({
  selector: 'app-choice-mutual',
  templateUrl: './choice-mutual.component.html',
  styleUrls: ['./choice-mutual.component.scss']
})
export class ChoiceMutualComponent implements OnInit {
  regions: RegionModel[] = [];
  selectedRegion: RegionModel | null = null;
  mutuelles: any[] = [];
  selectedMutuelleId: number | null = null;
  defaultRegionName: string = 'LOUGA';

  constructor(
    private dataService: DataService,
    private router: Router, 
    private toastr: ToastrService,
    private sponsorshipService: SponsorshipService,
    private regionService: RegionsService,
    
  ) { }

  ngOnInit() {
    this.loadRegions();
  }

  loadRegions() {
    this.regionService.findAll(1, 10, '', true).subscribe(
      response => {
        console.log('Response from regions endpoint:', response); 
        this.regions = response.data;
        this.setDefaultRegion();
      },
      error => {
        console.error('Error loading regions:', error);
        this.toastr.error('Erreur lors du chargement des régions');
      }
    );
  }

  setDefaultRegion() {
    this.selectedRegion = this.regions.find(region => region.libelle === this.defaultRegionName) || null;
    if (this.selectedRegion) {
      this.onRegionSelect();
    }
  }

  onRegionSelect() {
    if (this.selectedRegion) {
      const regionId = this.selectedRegion.id;
      this.regionService.findMutuellesByRegion(regionId).subscribe(
        response => {
          console.log('Response from mutuelles endpoint:', response);
          this.mutuelles = response.data;
        },
        error => {
          console.error('Error loading mutuelles:', error);
          this.toastr.error('Erreur lors du chargement des mutuelles');
        }
      );
    }
  }

  passerAuPaiement() {
    if (this.selectedMutuelleId === null) {
      this.toastr.error('Veuillez sélectionner une mutuelle');
      return;
    }
    console.log('Mutuelle sélectionnée :', this.selectedMutuelleId);
    const selectedMutuelle = this.mutuelles.find(mutuelle => mutuelle.id === this.selectedMutuelleId);
    if (!selectedMutuelle) {
      this.toastr.error('Mutuelle sélectionnée introuvable');
      return;
    }

    

    // const mutualData = {
    //   mutualId: this.selectedMutuelleId,
    //   mutual: {
    //     id: this.selectedMutuelleId,
    //     basicInfo: selectedMutuelle.basicInfo,
    //     extendedInfo: selectedMutuelle.extendedInfo,
    //     internalInfo: selectedMutuelle.internalInfo
    //   }
    // };
    const mutualData = {
      mutualId: this.selectedMutuelleId,
      mutual: {
        id: this.selectedMutuelleId,
        basicInfo: selectedMutuelle.basicInfo,
        extendedInfo: selectedMutuelle.extendedInfo,
        internalInfo: selectedMutuelle.internalInfo
      }
    };
    console.log('Mutual Data:', mutualData);
    
    this.dataService.setMutualData(mutualData);
    this.sponsorshipService.submitSponsorship().subscribe(
      response => {
        console.log('sponshor data:', response);
        this.dataService.setSponsorshipData(response);
        this.toastr.success("Submitting sponsorship");
        this.router.navigate(['/payment-detail']);
        
      },  
      error => {
        this.toastr.error('Erreur lors de la soumission du parrainage');
      }
    );
  }
}
