import { Injectable } from '@angular/core';
import { CrudService } from '~/commons/services';
import { PaymentMethod } from '../models/payment-method.model';
import { environment } from '~/environments/environment';
import { Endpoint } from '~/commons/constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodeService extends CrudService<PaymentMethod> {

  protected override api = environment.apiBenef;
  protected override endpoint = Endpoint.PAYMENT_METHOD;
  constructor(protected override httpClient: HttpClient) {
    super(httpClient);
  }


}
