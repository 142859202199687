// USA
export const locale = {
  lang: 'en',
  data: {

    TRANSLATOR: {
      SELECT: 'Select your language',
    },

    GENERAL:{
    },
    AUTH: {
      GENERAL: {
        OR: 'Ou',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Vous n\'avez pas de compte?',
        SIGNUP_BUTTON: 'Sign up',
        FORGOT_BUTTON: 'Mot de passe oublié ?',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        CONTACT: 'Contact',
        WELCOME: 'Welcome',
        SLOGAN: 'Lorem ipsum dolor sit amet consectetur adipiscing elit'
      },
      LOGIN: {
        TITLE: 'Créer un compte',
        BUTTON: 'Connexion',
        WELCOME_TEXT: 'Connectez-vous',
        ICI : 'Nouveau ici? ',
      },
      NEWPASSWORD: {
        TITLE: 'Configurer un nouveau mot de passe ?',
        SOUSTITLE: 'Vous avez déjà réinitialisé votre mot de passe ?',
      },
      FORGOT: {
        TITLE: 'Mot de passe oublié?',
        DESC: 'Entrez votre e-mail pour réinitialiser votre mot de passe.',
        SUCCESS: 'Your account has been successfully reset.',
        ENREGISTER : 'Envoyer',
        ANNULE : 'Annuler',
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.',
        COMPTE_CREER : 'Vous avez déjà un compte ?    ',
        SIGN_HERE :'Se connecter ici',
        LASTNAME: 'Nom',
        FIRSTNAME: 'Prénom',
        SYMBOLE_PASSWORD :'Utilisez 8 caractères ou plus avec un mélange de lettres, de chiffres & symboles.'
      },
  
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Prénom & Nom',
        PASSWORD: 'Mot de passe',
        CONFIRM_PASSWORD: 'Confirmez le mot de passe',
        PASSWORDNOW : 'Mot de passe actuel',
        PASSWORDCHANGE : ' Nouveau Mot de passe',
        USERNAME: 'Nom d\'utilisateur'
      },
      VALIDATION: {
        INVALID: '{{name}} n\'est pas valide',
        REQUIRED: '{{name}} est requis',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      }
    },
    ERROR: {
      400:{
        TEXT: 'Are you lost ?'
      }
    },
    TOPBAR: {
      MY_PROFILE: 'Mon profil',
      SETTINGS: 'Paramètres',
      SIGNOUT: 'Déconnexion',
    },
    BUTTONS:{
      ADD_BUTTON: 'Ajouter',
      CANCEL_BUTTON : 'Annuler',
      UPDATE_BUTTON : 'Modifier',
      DEACTIVATE_BUTTON : 'Désactiver',
      ACTIVATE_BUTTON : 'Activer',
      SUBMIT_BUTTON: 'Enregistrer',
      VALIDATE_BUTTON: 'Valider',
      CONFIRM_BUTTON: 'Confirmer',
      FILTER: 'Filtrer',
      SEARCH: 'Rechercher',
      EXPORT: 'Exporter',
      EXPORT_CSV: 'Exporter en CSV',
      EXPORT_PDF: 'Exporter en PDF',
      NEXT: 'Suivant',
      PREVIOUS: 'Precedant',
      BACK: 'Retour',
      CONTINUE: 'Continuer',
      GALLERY: 'Galerie',
      DETAIL: 'Détail',
      FINISH: 'Terminer',
    },
  },
};
