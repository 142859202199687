import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '~/environments/environment';
import { Endpoint } from '~/commons/constants';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(private http: HttpClient) {}

  sendMail(messagePayload: any): Observable<any> {
    
    return this.http.post<any>(`${environment.apiAuth}${Endpoint.MAIL}`, messagePayload)
  }
}
