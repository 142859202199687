import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PaymentMethodeService } from '~/app/modules/auth/services/payment-methode.service';
import { DataService } from '~/app/sponsorship/data.service';
import { PaymentService } from '~/app/sponsorship/services/payment-service.service';

@Component({
  selector: 'app-payment-initiative',
  templateUrl: './payment-initiative.component.html',
  styleUrls: ['./payment-initiative.component.scss']
})
export class PaymentInitiativeComponent implements OnInit{
  initiativeId!: number;
  donationId! : number
  selectedType: string = '';
  selectedParrainage: string = '';
  contactPerson: string = '';
  amount: number = 0;
  selectedMutuelle: string = '';
  selectedPaymentMethod: string = '';
  phone: string = '';
  nbBeneficiaries: number = 0;
  paymentMethodId: number = 1; // Valeur par défaut

  paymentMethods: { name: string, image: string, commissionRate: number, totalAmount: string, paymentMethodId: number, categorie: string }[] = [];

  // Card details properties
  cardNumber: string = '';
  cardExpireYear: string = '';
  cardExpireMonth: string = '';
  cvv: string = '';
  cardHolderName: string = '';
  clientFirstname: string = '';
  clientLastname: string = '';

  constructor(
    private dataService: DataService,
    private paymentService: PaymentService,
    private router: Router,
    private toastr: ToastrService,
    
    private paymentMethodeService: PaymentMethodeService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.initiativeId = params['initiativeId'];
      this.donationId = params['donationId']; // Récupérer l'ID du don
      console.log("🚀 ~ PaymentInitiativeComponent ~ ngOnInit ~ donationId:",this.donationId)
      if (!this.initiativeId || !this.donationId) {
        console.error('Missing initiative ID or donation ID');
      }
    });

    const paymentData = this.dataService.getPaymentData();
    this.amount = paymentData.total || 0;

    this.loadPaymentMethods();
  }
  
  
  
  loadPaymentMethods() {
    this.paymentMethodeService.findAll(0, 10, '', true).subscribe(
      response => {
        console.log('Response from payment methods endpoint:', response); 
        this.paymentMethods = response.data.map(paymentMethod => ({
          name: paymentMethod.operateur.nom,
          image: paymentMethod.operateur.urlLogo,
          commissionRate: paymentMethod.taux,
          totalAmount: '0000 Cfa',
          paymentMethodId: paymentMethod.id,
          categorie: paymentMethod.categorie
        }));
      },
      error => {
        this.toastr.error('Erreur lors du chargement des méthodes de paiement');
      }
    );
  }

  selectPaymentMethod(method: string): void {
    this.selectedPaymentMethod = method;
    const selectedMethod = this.paymentMethods.find(m => m.name === method);
    if (selectedMethod) {
      this.paymentMethodId = selectedMethod.paymentMethodId;
    }
  }

  getSelectedPaymentImage(): string {
    const method = this.paymentMethods.find(m => m.name === this.selectedPaymentMethod);
    return method ? method.image : '';
  }

  showCardFields(): boolean {
    const selectedMethod = this.paymentMethods.find(m => m.name === this.selectedPaymentMethod);
    return selectedMethod ? selectedMethod.categorie === 'card' : false;
  }

  validatePayment(): void {
    // if (!this.phone) {
    //   this.toastr.error('Veuillez remplir tous les champs');
    //   return;
    // }

    const sponsorshipData = this.dataService.getSponsorshipData();
    const selectedMethod = this.paymentMethods.find(m => m.name === this.selectedPaymentMethod);

    const transactionDetails: any = {
      id: this.paymentMethodId,
      recipientPhoneNumber: this.phone,
      donation: {
        id: this.donationId
      },
      paymentMethodId: this.paymentMethodId
    };

    if (selectedMethod?.categorie === 'card') {
      transactionDetails.cardNumber = this.cardNumber;
      transactionDetails.cardExpireYear = this.cardExpireYear;
      transactionDetails.cardExpireMonth = this.cardExpireMonth;
      transactionDetails.cvv = this.cvv;
      transactionDetails.cardHolderName = this.cardHolderName;
      transactionDetails.clientFirstname = this.clientFirstname;
      transactionDetails.clientLastname = this.clientLastname;
    }

    this.paymentService.initiateTransaction(transactionDetails).subscribe(
      transactionResponse => {
        const transactionData = {
          id: transactionResponse.id,
          createdAt: new Date().toISOString(),
          status: true,
          amount: this.amount,
          fee: transactionResponse.fee,
          amountTotal: transactionResponse.amountTotal,
          recipientPhoneNumber: this.phone,
          transactionStatus: transactionResponse.status,
          paymentMethodId: transactionDetails.paymentMethodId,
          paymentMethod: {
            id: transactionDetails.paymentMethodId,
            extendedInfo: 'string',
            internalInfo: 'string'
          },
          transactionType: selectedMethod?.categorie === 'card' ? 'CARD' : 'WAVE',
          extTransactionId: transactionResponse.extTransactionId
        };

        this.dataService.setTransactionData(transactionData);
        if (transactionResponse.launchUrl) {
          this.toastr.success("Payment initié avec succès");
          window.location.href = transactionResponse.launchUrl;
        } else {
          this.toastr.success("Payment initié avec succès");
          this.router.navigate(['/scanner']);
        }
      },
      error => {
        this.toastr.error("Erreur lors de l'initiation de la transaction. Veuillez réessayer.");
      }
    );
  }

  navigateToPayment(): void {
    if (this.initiativeId) {
      this.router.navigate(['/payment-initiative'], { queryParams: { initiativeId: this.initiativeId } });
    } else {
      console.error('No initiative ID provided');
    }
  }
  

  cancelPayment(): void {
    this.router.navigate(['/previous-route']);
  }

}
