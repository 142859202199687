<div class="contain">
    <div class="main">
        <div class="sponsorship-individual-container d-flex justify-content-center">
            <div class="content-container col-md-6 text-white">
                <h1 class="text-white">Vous permet de profiter d’une couverture santé.</h1>
                <ul>
                    <li>Donner les informations qui différencie les types d’adhésions</li>
                    <li>Donner les exceptions</li>
                </ul>
                <h3 class="text-white"> Personne à contacter</h3>
                <form>
                    <div class="border-dark">
                        <div class="form-group">
                            <label for="prenom">Prénom</label>
                            <input type="text" class="form-control" id="prenom" placeholder="Ousseynou"
                                [(ngModel)]="contactFirstName" name="contactFirstName">
                        </div>
                        <div class="form-group">
                            <label for="nom">Nom</label>
                            <input type="text" class="form-control" id="nom" placeholder="Thiam"
                                [(ngModel)]="contactLastName" name="contactLastName">
                        </div>
                        <div class="form-group">
                            <label for="telephone">Téléphone</label>
                            <!-- <div class="col-sm-9 w-100">
                                <ngx-intl-tel-input [cssClass]="'form-control form-control-solid'" [onlyCountries]="['sn']"
                                [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="false"
                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                [selectFirstCountry]="false" [selectedCountryISO]="selectedC" [maxLength]="9"
                                [phoneValidation]="true" [separateDialCode]="true" name="contactPhone" [(ngModel)]="contactPhone"
                                (ngModelChange)="selectPhone($event)"></ngx-intl-tel-input>
                            </div> -->
                            <input type="tel" class="form-control" id="telephone" placeholder="(+221) 77 123 45 67" [(ngModel)]="contactPhone" name="contactPhone">
                           
                        </div>
                    </div>
                    <label class="mb-3 mt-3">Combien y a-t-il de bénéficiaires ?</label>
                    <input type="number" class="form-control mb-3" [(ngModel)]="numberOfBeneficiaries"
                        name="numberOfBeneficiaries" min="0">
                    <button type="submit" class="btn btn-secondary mt-10 w-100 text-white" (click)="onSubmit()">Choisir
                        une mutuelle</button>
                </form>
            </div>
            <div class="image-container col-md-6">
                <img src="../../../assets/images/family.png" alt="Image descriptive" class="img-fluid">
            </div>
        </div>
    </div>
</div>