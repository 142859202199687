import { Component } from '@angular/core';

@Component({
  selector: 'app-details-inscription',
  templateUrl: './details-inscription.component.html',
  styleUrls: ['./details-inscription.component.scss']
})
export class DetailsInscriptionComponent {

}
