export enum Endpoint{
    LOGIN = "/auth/login",
    SIGNUP = "/donors/register",
    FORGGOTPWD = "/auth/forgotten-password",
    INDUSTRIES = "/industries",
    COMPANYS = "/company-categories",
    CHECKOTP = "/auth/check-otp",
    CHANGEPWD= "/auth/forgotten-password/validate",
    UPDATE_PHONE= "/auth/me/update-phone" ,
    UPDATE_EMAIL = "/auth/me/update-email",
    CHANGEPASS= "/auth/me/change-password",
    SENDOTP= "/auth/me/generate-change-email-otp",
    UPDATE_USER_NAME="/auth/me",
    UPDATE_PROFILE_PICTURE= "/users/me/avatar",
    SPONSHOR="/sponsorships/me",
    MEMBERSHIP="/memberships/me",
    PAID="/transactions",
    BENEFICIARIES="/beneficiaries/me",
    ADD_BENEFICIARY="/memberships/me/add-beneficiary",
    LIST_BENEF ="/memberships/me",
    PAYMENT_METHOD="/payment-methods/available",
    LIST_INITIAVTIVE="/initiatives",
    LIST_REGIONS="/gestam/regions",
    LIST_RELATION="/gestam/relations",
    DONATION="/donations/me",
    MAIL="/auth/contact-us"
}
