import { Component } from '@angular/core';
import { AuthService } from '../../auth/services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {

  isLoggedIn: boolean = false;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    if(this.authService.getJwtToken()){
      this.authService.identity();
    }
    this.authService.isAuthenticated$().subscribe({
      next:(val)=>{
        this.isLoggedIn = val;
      }
    });
  }

  
  logout() {
    this.authService.logout();
    this.isLoggedIn = false;
  }

}
