<app-navbar/>
<div class="container1">
    <div class="container2">
        
        <div class="title">
            <h2 class="text-center mt-3">Pour toute information sur nos activités</h2>
            <p class=" text-center mt-3">Besoin d'une assistance immédiate ou simplement envie de nous contacter ?
                </p>
                <p class="text-center mt-3">Notre page de contact est là pour vous simplifier la vie !</p>
        </div>
        <div>
            <div class="box">
                <div class="box1">
                    <div class="div1">
                        <img src="../../../assets/images/appels.png" alt="" srcset="">
                    </div>
                    <div class="div2">
                        <h1>Service d'appelle</h1>
                        <p>+221 33 859 15 15 </p>
                    </div>
                </div>
                <div class="box1">
                    <div>
                        <img src="../../../assets/images/mail.png" alt="" srcset="">
                    </div>
                    <div class="div2">
                        <h1>Notre e-mail</h1>
                        <p>contact@sunucsu.sn </p>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="box">
                <div class="box1">
                    <div>
                        <img src="../../../assets/images/position.png" alt="" srcset="">
                    </div>
                    <div class="div2">
                        <h1>Notre position</h1>
                        <p>Cité Keur Gorgui.... </p>
                    </div>
                </div>
                <div class="box1">
                    <div>
                        <img src="../../../assets/images/heure.png" alt="" srcset="">
                    </div>
                    <div class="div2">
                        <h1>Heure de travail</h1>
                        <p>De 8h á 18h - 7jour/7</p>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="form">
        <div class="title">
            <h2 class="text-center mt-3">Pour toute information sur nos activités</h2>
            <p class=" text-center mt-3">Besoin d'une assistance immédiate ou simplement envie de nous contacter ?
                </p>
                <p class="text-center mt-3">Notre page de contact est là pour vous simplifier la vie !</p>
        </div>
        <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm.valid)">
            <div class="form-group d-flex  justify-content-center align-items-center">
                
                <div class="">
                    <input type="text" required [ngModelOptions]="{standalone: true}" name="lastName" #lastName="ngModel" id="lastName" [(ngModel)]="contactDetails.lastName" class="form-control form-control-solid" placeholder="Nom">
                    <ul class="parsley-errors-list filled" *ngIf="!contactDetails.lastName.valid && (lastName.touched || contactForm.submitted)">
                        <li class="parsley-required" *ngIf="lastName.hasError('required')">Le nom est obligatoire.</li>
                    </ul>
                </div>
                <div>
                    <input type="text" [ngModelOptions]="{standalone: true}" required #firstName="ngModel" id="firstName" nme="firstName" [(ngModel)]="contactDetails.firstName" class="form-control form-control-solid" placeholder="Prénom">
                    <ul class="parsley-errors-list filled" *ngIf="!contactDetails.firstName.valid && (firstName.touched || contactForm.submitted)">
                        <li class="parsley-required" *ngIf="firstName.hasError('required')">Le prénom est obligatoire.</li>
                    </ul>
                </div>
            </div>
            <div class="form-group d-flex  justify-content-center align-items-center">
                
                <div class="">
                    <input type="email" required #email="ngModel" [ngModelOptions]="{standalone: true}" id="email" nme="email"  [(ngModel)]="contactDetails.email" class="form-control form-control-solid" placeholder="Email">
                    <ul class="parsley-errors-list filled" *ngIf="!contactDetails.email.valid && (email.touched || contactForm.submitted)">
                        <li class="parsley-required" *ngIf="email.hasError('required')">L'email est obligatoire.</li>
                    </ul>
                </div>
                <div>
                    <input type="text" required #phone="ngModel" [ngModelOptions]="{standalone: true}" id="phone" [(ngModel)]="contactDetails.phone" class="form-control form-control-solid" placeholder="Telephone">
                    <ul class="parsley-errors-list filled" *ngIf="!contactDetails.phone.valid && (phone.touched || contactForm.submitted)">
                        <li class="parsley-required" *ngIf="phone.hasError('required')">Le Téléphone est obligatoire.</li>
                    </ul>
                </div>
            </div>
            <div class="form-group d-flex justify-content-center align-items-center">
                <div>
                    <textarea id="message" required #message="ngModel" id="message" [ngModelOptions]="{standalone: true}" [(ngModel)]="contactDetails.message" class="form-control form-control-solid" placeholder="Message"></textarea>
                    <ul class="parsley-errors-list filled" *ngIf="!contactDetails.message.valid && (message.touched || contactForm.submitted)">
                        <li class="parsley-required" *ngIf="message.hasError('required')">Le message est obligatoire.</li>
                    </ul>
                </div>
            </div>
            <div class="form-group d-flex justify-content-center align-items-center">
                <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token" required [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha>
                <div *ngIf="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)" class="invalid-feedback">
                    <div *ngIf="recaptcha.errors?.['required']">This field is required.</div>
                </div>
            </div>
            <div class="d-flex justify-content-center button">
                <button type="submit" id="send" class="btn ">Envoyer</button>
            </div>
            <!-- <re-captcha (resolved)="resolved($event)" siteKey="6LfKHDwqAAAAAJTtZ9CfrYxdk3LGra_tSdRTWIbc"></re-captcha> -->
        </form>
       
    </div> 
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3858.963808151521!2d-17.47332912489163!3d14.71463828578538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xec112c3a6f2c041%3A0x9cf1220581c85055!2sAgence%20de%20la%20CMU!5e0!3m2!1sfr!2ssn!4v1725984946192!5m2!1sfr!2ssn" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    <!-- <div class="map">

    </div> -->
</div>
<app-footer/>
