<div class="contain">
    <div class="main">
      <div class="sponsorship-individual-container d-flex justify-content-center">
        <div class="content-container col-md-6 text-white">
          <h1 class="text-white">Vous permet de profiter d’une couverture santé.</h1>
          <ul>
            <li>Donner les informations qui différencie les types d’adhésions</li>
            <li>Donner les exceptions</li>
          </ul>
          <label>Connaissez-vous le(s) bénéficiaire(s) ?</label>
          <div class="form-control d-flex justify-content-evenly">
            <div class="form-check form-check-inline">
              <input type="radio" id="oui" name="beneficiaire" value="oui" class="text-primary"
                     (change)="onRadioChange($event)">
              <label class="me-2 ms-2 text-primary" for="oui">Oui</label>
            </div>
            <div>
              <input type="radio" id="non" name="beneficiaire" value="non" class="text-primary"
                     (change)="onRadioChange($event)">
              <label class="me-2 ms-2 text-primary" for="non">Non</label>
            </div>
          </div>
          <div *ngIf="knowBeneficiaries">
            <div class="mt-4">
              <h5 class="text-secondary">Liste des bénéficiaires</h5>
              <ul class="list-group">
                <li *ngFor="let beneficiary of beneficiaries; let i = index" class="list-group-item d-flex justify-content-between align-items-center">
                  <div class="beneficiary-info">
                    <span class="badge badge-secondary me-2">{{ i + 1 }}</span>
                    <div class="beneficiary-details">
                      <strong>{{ beneficiary.firstName }} {{ beneficiary.lastName }}</strong>
                      <p>{{ beneficiary.phoneNumber }}</p>
                    </div>
                  </div>
                  <button class="btn btn-secondary btn-sm" (click)="removeBeneficiary(i)">Retirer</button>
                </li>
              </ul>
            </div>
            <form (ngSubmit)="addBeneficiary()">
              <div class="border-dark">
                <div class="form-group">
                  <label for="prenom">Prénom</label>
                  <input type="text" class="form-control" id="prenom"
                         [(ngModel)]="newBeneficiary.firstName" name="firstName" required>
                </div>
                <div class="form-group">
                  <label for="nom">Nom</label>
                  <input type="text" class="form-control" id="nom" [(ngModel)]="newBeneficiary.lastName"
                         name="lastName" required>
                </div>
                <div class="form-group">
                  <label for="telephone">Téléphone</label>
                  <input type="tel" class="form-control" id="telephone"
                         [(ngModel)]="newBeneficiary.phoneNumber" name="phoneNumber" required>
                </div>
              </div>
              <button type="submit" class="btn btn-secondary mt-3 w-100">Ajouter un bénéficiaire</button>
            </form>
          </div>
          <div *ngIf="!knowBeneficiaries">
            <label class="mb-3 mt-3">Combien y a-t-il de bénéficiaires ?</label>
            <input type="number" class="form-control mb-3" [(ngModel)]="numberOfBeneficiaries" min="0">
          </div>
          <p class="text-secondary">Montant à payer (FCFA): - - - - Cfa/Membre</p>
          <div class="border-dark">
            <label>Pour compléter l’inscription des bénéficiaires, nous aurons besoin d’informations les
              concernants. Qui serait en mesure de nous les donner ?</label>
            <label class="mt-2">Qui devrions-nous contacter ?</label>
            <select class="form-control mb-3 mt-3" (change)="onContactPersonChange($event)">
              <option value="contact1">Contacter la mutuelle</option>
              <option value="contact2">Contacter quelqu'un d'autre</option>
            </select>
          </div>
          <form>
            <div class="border-dark">
              <div class="form-group">
                <label for="prenom">Prénom</label>
                <input type="text" class="form-control" id="prenom" [(ngModel)]="contactFirstName" name="contactFirstName">
              </div>
              <div class="form-group">
                <label for="nom">Nom</label>
                <input type="text" class="form-control" id="nom" [(ngModel)]="contactLastName" name="contactLastName">
              </div>
              <div class="form-group">
                <label for="telephone">Téléphone</label>
                <input type="tel" class="form-control" id="telephone" [(ngModel)]="contactPhone" name="contactPhone">
              </div>
            </div>
          </form>
          <button type="submit" class="btn btn-secondary mt-10 w-100 text-white" (click)="onSubmit()">Choisir une mutuelle</button>
        </div>
        <div class="image-container col-md-6">
          <img src="../../../assets/images/individual.png" alt="Image descriptive" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
  