<div class="payment-error-container">
    <div class="error-icon">
       
        <svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 352 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
            <path fill="#f44336"
                d="M242.7 256l100.1-100.1c12.3-12.3 12.3-32.2 0-44.5l-22.2-22.2c-12.3-12.3-32.2-12.3-44.5 0L176 189.3 75.9 89.2c-12.3-12.3-32.2-12.3-44.5 0L9.2 111.5c-12.3 12.3-12.3 32.2 0 44.5L109.3 256 9.2 356.1c-12.3 12.3-12.3 32.2 0 44.5l22.2 22.2c12.3 12.3 32.2 12.3 44.5 0L176 322.7l100.1 100.1c12.3 12.3 32.2 12.3 44.5 0l22.2-22.2c12.3-12.3 12.3-32.2 0-44.5L242.7 256z" />
        </svg>
    
    </div>
    <h1>Échec du paiement</h1>
    <p>Votre paiement n'a pas pu être traité.</p>
    <div class="error-details">
        <p>Veuillez vérifier vos informations de paiement et réessayer.</p>
    </div>
    <div class="d-flex flex-row">
        <button (click)="retryPayment()">Réessayer</button>
    <button (click)="goToHome()">Retour à l'accueil</button>
    </div>
    
</div>