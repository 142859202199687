<app-navbar/>
<div class="container-fluid" id="block_annuaire">
    <!-- <div class="maps">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1978576.593390445!2d-14.364391938734007!3d14.403662404350019!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sfr!2ssn!4v1728297238656!5m2!1sfr!2ssn" width="100%" height="650" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div> -->
    <div class="maps">
        <google-map [center]="center" [zoom]="zoom" width="100%" height="650px">
            <map-marker *ngFor="let position of markerPositions" [clickable]="true" [title]="position.title" [label]="position.label" [position]="position.position"></map-marker>
        </google-map>
    </div>
    <div class="searchblock">
        <form #searchForm="ngForm" (ngSubmit)="onSubmit(searchForm.valid)">
            <div class="row">
                <div class="col-md-3 blone">
                    <select name="typeStructure" (change)="choixStructure()" id="typeStructure" #typeStructure="ngModel" [(ngModel)]="data.typeStructure">
                        <option  [selected]="data.typeStructure" *ngFor="let type of types;" value="{{type.code}}">{{type.nom}}</option>
                        
                    </select>
                </div>
                <div class="col-md-3 blone">
                    <select name="regions" (change)="choixRegion()" id="regions" #region="ngModel" [(ngModel)]="data.region">
                        <option [selected]="selectRegion" value="">Région</option>
                        <option [selected]="selectRegion ? reg.id-reg.libelle == selectRegion : currentRegionName.toUpperCase() == reg.libelle" *ngFor="let reg of regions;" value="{{reg.id}}-{{reg.libelle}}">{{reg.libelle}}</option>
                        
                    </select>
                </div>
                <div class="col-md-3 blone">
                    <select name="departement" id="departement" #departement="ngModel" [(ngModel)]="data.departement">
                        <option [selected]="data.departement" value="aucun">Département</option>
                        <option [selected]="data.departement" *ngFor="let dept of depts;" value="{{dept.libelle}}">{{dept.libelle}}</option>
                    </select>
                </div>
                <div class="col-md-3 blone d-flex justify-content-center button" style="padding-left: 20px;">
                    <button type="submit" id="send" class="btn ">Rechercher</button>
                </div>
            </div>
        </form>
    </div>
</div>
<div>
    <div class="trouve">
        <h1>Voici le resultat de votre recherche <span *ngIf="currentRegionName">sur {{currentRegionName.toUpperCase()}}</span></h1>
        <div class="result">
            <div class="block"> 
                <div class="row">
                    <div class="col-md-3" *ngFor="let item of structures | paginate: { itemsPerPage: 12, currentPage: p, totalItems:totalItems  }">
                        <div class="item">
                            <div class="logo"><img src="../../../../assets/images/position.png" /></div>
                            <div class="specialiste"><strong>{{item.structure}}</strong></div>
                            <div class="specialite">{{item.adresse}}</div>
                            <div class="tel"><img src="../../../../assets/images/tel.svg" /><span><strong></strong>{{item.telephone}}</span></div>
                        </div>
                    </div>

                    <pagination-controls  (pageChange)="gty((p = $event))"></pagination-controls>
                    <!-- <pagination-controls *ngIf="structures.count > 0" (pageChange)="gty((p = $event))"></pagination-controls> -->
                    
                    <!-- <div class="col-md-3">
                        <div class="item">
                            <div class="logo"><img src="../../../../assets/images/position.png" /></div>
                            <div class="specialiste"><strong>Mamadou Bamba SY</strong></div>
                            <div class="specialite">Cardiologue</div>
                            <div class="tel"><img src="../../../../assets/images/tel.svg" /><span><strong></strong>+221 77 405 67 56</span></div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item">
                            <div class="logo"><img src="../../../../assets/images/position.png" /></div>
                            <div class="specialiste"><strong>Mamadou Bamba SY</strong></div>
                            <div class="specialite">Cardiologue</div>
                            <div class="tel"><img src="../../../../assets/images/tel.svg" /><span><strong></strong>+221 77 405 67 56</span></div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item">
                            <div class="logo"><img src="../../../../assets/images/position.png" /></div>
                            <div class="specialiste"><strong>Mamadou Bamba SY</strong></div>
                            <div class="specialite">Cardiologue</div>
                            <div class="tel"><img src="../../../../assets/images/tel.svg" /><span><strong></strong>+221 77 405 67 56</span></div>
                        </div>
                    </div> -->
                </div>
                <div class="row ">
                    <div class="col-md-12">
                        <div class="pagination">
                            <!-- <div class="list">
                                    <ul>
                                        <li *ngFor="let item of structures | paginate: { itemsPerPage: 12, currentPage: p, totalItems:structures.count  }">{{ item }}</li>
                                    </ul>
                            
                                    <pagination-controls (pageChange)="gty((p = $event))"></pagination-controls>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer/>