import { Injectable } from '@angular/core';
import { RelationModel } from '../models/relation.model';
import { CrudService } from '~/commons/services';
import { environment } from '~/environments/environment';
import { Endpoint } from '~/commons/constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RelationService  extends CrudService<RelationModel> {
  protected override api = environment.apiBenef;
  protected override endpoint = Endpoint.LIST_RELATION;

  constructor(protected override httpClient: HttpClient) {
    super(httpClient);
  }
}
