import { Component, OnInit } from '@angular/core';
import { AuthService } from '~/app/modules/auth/services/auth.service';
import { CurrentUser } from '~/app/modules/auth/models/currentUser.model';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  currentUser: CurrentUser | null = null;
  welcomeMessage: string = '';

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.authService.getCurrentUser().subscribe(
      (user: CurrentUser | null) => {
        //console.log('Informations utilisateur :', user);
        this.currentUser = user;
        if (this.currentUser) {
          //console.log('Informations utilisateur :', this.currentUser);
        }
        this.welcomeMessage = `Bonjour ${this.currentUser?.firstName}  ${this.currentUser?.lastName}`;
      },
      (error: any) => {
        //console.error('Erreur lors de la récupération des informations de l\'utilisateur :', error);
      }
    );
  }
}
