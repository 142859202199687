<app-navbar></app-navbar>
<div class="overlay">
    <div class="text-center main">
        <h3 class="text-secondary">{{ title }}</h3>
        <p class="text-white custom-text">Sélectionnez un type de couverture</p>
    </div>

    <div class="mt-10 d-flex justify-content-center">
        <div *ngFor="let button of buttons" class="m-2 button-container">
            <button class="btn w-100 text-center" 
                    [ngClass]="{
                        'btn-custom-primary': selectedButton === button.label,
                        'btn-custom-secondary': selectedButton !== button.label
                    }" 
                    (click)="handleButtonClick(button.label)">
                {{ button.label }}
            </button>
        </div>
    </div>

    <div class="d-flex justify-content-center align-items-center" *ngIf="selectedButton">
        <app-sponsorship-individual *ngIf="selectedButton === 'Parrainage(s) individuel(s)'"></app-sponsorship-individual>
        <app-sponsorship-family *ngIf="selectedButton === 'Parrainage Familial'"></app-sponsorship-family>
        <app-sponsorship-student-base-packet *ngIf="selectedButton === 'Packet de Base'"></app-sponsorship-student-base-packet>
        <app-sponsorship-student-complement-packet *ngIf="selectedButton === 'Packet Complémentaire'"></app-sponsorship-student-complement-packet>
    </div>
</div>

<app-footer></app-footer>
