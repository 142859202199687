import { Component, OnInit } from '@angular/core';
import { AnnuaireService } from '../../services/annuaire.service';

@Component({
  selector: 'app-pharmacies',
  templateUrl: './pharmacies.component.html',
  styleUrls: ['./pharmacies.component.scss']
})
export class PharmaciesComponent implements OnInit {

	public data:any = {page:'', codeType:'', region:'', departement:''};
	public loading:boolean = false;
  public searchValues:any = {
    'page': this.data?.page,
    // "codeType": this.data?.codeType,
    "codeType": 'PHARMACIE',
    "region": this.data?.region,
    "departement": this.data?.departement,
    "size": 12
  }

  collection:any = [];
  structures:any = [];
  regions:any = [];
  selectRegion:any = '';
  depts:any = [];
  currentPage = 1;
  currentRegionName:any = ''
  p = 1;

  itemsPerPage = 12;
  totalItems : any = 0; 
  center: google.maps.LatLngLiteral = { lat: 14.4196684, lng: -15.8593242 };
  zoom = 7.63;
  markerPositions: any[] = [];
  markerInfoContent:any = '';
  point: any = {}//current position
  constructor(private annuaireService: AnnuaireService) {
  }

  ngOnInit() {
    this.getAllRegions();
    // this.getPharmacies(this.searchValues);
    // this.getPoints();
    this.getCurrentLocation();
    this.getIPAddress();
  }

  // markerPositions: any[] = [
  //   { position: {lat: 14.866106, lng: -16.804148 }, 
  //   title: 'Marker label 1' ,
  //     // label: {
  //     //   color: 'red',
  //     //   text: 'Marker label 1' ,
  //     // },
  //   },
  //   { position: {lat: 14.791763, lng: -17.304026}, 
  //     title: 'Marker label 2' ,
  //     // label: {
  //     //   color: 'red',
  //     //   text: 'Marker label 2' ,
  //     // }, 
  //   }
  // ];

  

  gty(pageSlected: any){
    this.searchValues.page = pageSlected;
    this.annuaireService.getStructures(this.searchValues).subscribe((res:any) => {
        this.loading = false;
        this.structures = res.data
        this.totalItems = res.totalItems
      },
      err => {
        this.loading = false;
        // this.modalService.dismissAll();
      }
    );
  }

  getAllRegions(){
    this.annuaireService.getAllRegions().subscribe((res:any) => {
      this.loading = false;
      console.log(res)
      this.regions = res.data
    },
    err => {
      this.loading = false;
      // this.modalService.dismissAll();
    }
  );
  }

  choixRegion (){
    this.loading = true;
    let spl = this.data.region.split('-');
    this.selectRegion = spl[1]
    this.annuaireService.getDepsByRegion(spl[0])
      .subscribe(res => {
        // console.log(res['hydra:member'])
        this.depts = res.data;
        this.loading = false;
      });
  }

  getPharmacies(filter:any){
    this.annuaireService.getStructures(this.searchValues).subscribe((res:any) => {
        this.loading = false;
        this.totalItems = res.totalItems
        this.structures = res.data
        
      },
      err => {
        this.loading = false;
        // this.modalService.dismissAll();
      }
    );
  }

  getPoints(region:any){
    var cardValues = {
      "codeType": 'PHARMACIE',
      "region": region,
      "departement": '',
      "size": 50,
      "page": 1,
    }
    this.annuaireService.getStructures(cardValues).subscribe((res:any) => {
        this.loading = false;
        for (var i= 0 ; i<res.data.length ; i++){
          if (res.data[i].latitude && res.data[i].longitude){
            this.markerPositions.push( 
              {
                position: {lat: Number(res.data[i].latitude)  , lng: Number(res.data[i].longitude)  }, 
                title: res.data[i].structure,
                // label: {
                //   color: 'red',
                //   text: 'Marker label 1' ,
                // },
              }
            ) 
          }
        }
        
      },
      err => {
        this.loading = false;
        // this.modalService.dismissAll();
      }
    );
  }

  onSubmit(isValid: any){
		if (isValid){
			console.log('submit', this.data);
      this.searchValues.codeType='PHARMACIE'
      this.searchValues.page = this.p
      this.searchValues.size = 12
      this.searchValues.departement = this.data.departement
      if (this.data.departement == 'aucun'){
        this.searchValues.departement = ''
        this.searchValues.region = this.selectRegion;
      }
      if (this.selectRegion) {
        this.searchValues.region = this.selectRegion;
        this.currentRegionName = ''
      }
      this.loading = true;
      // this.annuaireService.getStructures(patient).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res:any) => {
      this.annuaireService.getStructures(this.searchValues).subscribe((res:any) => {
          this.loading = false;
          this.structures = res.data
          this.totalItems = res.totalItems
          for (var i= 0 ; i<res.data.length ; i++){
            if (res.data[i].latitude && res.data[i].longitude){
              console.log(res.data[i].latitude, res.data[i].longitude)
              this.markerPositions.push( 
                {
                  position: {lat: Number(res.data[i].latitude)  , lng: Number(res.data[i].longitude)  }, 
                  title: res.data[i].structure 
                  // label: {
                  //   color: 'red',
                  //   text: 'Marker label 1' ,
                  // },
                }
              ) 
            }
          }
        },
        err => {
          this.loading = false;
			    // this.modalService.dismissAll();
        }
      );
		} else{
			
			console.log('submit not valid');
		}
	}

  getCurrentLocation() {
    this.loading = true;
  
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        this.loading = false;
        this.point = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        console.log(position)
        this.geocodeLatLng(this.point);
        // google.maps.LatLngLiteral = { lat: 14.4196684, lng: -15.8593242 }
        this.center = this.point;
        // this.map.panTo(point);
  
        this.markerInfoContent = "I'm here!";
        
      },
      (error) => {
        this.loading = false;
  
        if (error.PERMISSION_DENIED) {
          alert("Couldn't get your location Permission denied");
        } else if (error.POSITION_UNAVAILABLE) {
          alert(
            "Couldn't get your location Position unavailable"
          );
        } else if (error.TIMEOUT) {
          alert("Couldn't get your location Timed out'");
        } else {
          alert(error.message);
        }
      },
      { enableHighAccuracy: true }
    );
  }

  geocodeLatLng(location: any): Promise<any> {
    let geocoder = new google.maps.Geocoder();
  
    return new Promise((resolve, reject) => {
      geocoder.geocode({ 'location': location }, (results:any, status:any) => {
        // const response = new GeocoderResponse(status, results);
        console.log(status, results);
        // resolve(response);
      });
    });
  }

  public getIPAddress() {
    this.annuaireService.getIPAddress().subscribe((res:any) => {
      this.loading = false;
      this.getInfoVille(res.ip)
    },
    err => {
      this.loading = false;
      // this.modalService.dismissAll();
    }
  );
  }

  public getInfoVille(ip:any) {
      this.annuaireService.getInfoVille(ip).subscribe((res:any) => {
        this.loading = false;
        this.currentRegionName = res.regionName
        this.getPoints(res.regionName);
        this.searchValues.region = res.regionName
        
        this.getPharmacies(this.searchValues);
      },
      err => {
        this.getPharmacies(this.searchValues);
        this.loading = false;
        // this.modalService.dismissAll();
      }
    );
  }
}
