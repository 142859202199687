import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Donation } from './model/donation.model';
import { Observable } from 'rxjs';
import { environment } from '~/environments/environment';
import { Endpoint } from '~/commons/constants';

@Injectable({
  providedIn: 'root'
})
export class DonationService {


  constructor(private http: HttpClient) { }

  createDonation(donation: Donation): Observable<Donation> {
    return this.http.post<Donation>(`${environment.apiBenef}${Endpoint.DONATION}`, donation);
  }
}
