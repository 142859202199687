import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private sponsorshipData: any = {};
  private mutualData: any = {};
  private paymentData: any = {};
  private transactionData: any = {};
  private selectedButton: string = '';

  setSponsorshipData(data: any): void {
    this.sponsorshipData = data;
  }

  getSponsorshipData(): any {
    return this.sponsorshipData;
  }

  setMutualData(data: any): void {
    this.mutualData = data;
  }

  getMutualData(): any {
    return this.mutualData;
  }

  setPaymentData(data: any): void {
    this.paymentData = data;
  }

  getPaymentData(): any {
    return this.paymentData;
  }

  setTransactionData(data: any): void {
    this.transactionData = data;
  }

  getTransactionData(): any {
    return this.transactionData;
  }

  getAllData(): any {
    return {
      sponsorship: this.sponsorshipData,
      mutual: this.mutualData,
      payment: this.paymentData,
      transaction: this.transactionData
    };
  }

  setSelectedButton(button: string): void {
    this.selectedButton = button;
  }

  getSelectedButton(): string {
    return this.selectedButton;
  }
}
