import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InitiativeService } from '../service/initiative.service';
import { Initiative } from '../model/initiative.model';

@Component({
  selector: 'app-categorie-initiative',
  templateUrl: './categorie-initiative.component.html',
  styleUrls: ['./categorie-initiative.component.scss']
})
export class CategorieInitiativeComponent implements OnInit {
  initiativeId!: string;
  initiative: Initiative | null = null;
  title: string = '';
  description: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private initiativeService: InitiativeService
  ) { }

  ngOnInit(): void {
    this.initiativeId = this.route.snapshot.paramMap.get('id')!;
    if (this.initiativeId) {
      this.getInitiativeDetails(this.initiativeId);
    } else {
      console.error('No initiative ID provided');
    }
  }

  getInitiativeDetails(id: string): void {
    this.initiativeService.getInitiativeById(id).subscribe(
      (data: Initiative) => {
        this.initiative = data;
        
      },
      error => {
        console.error('Error fetching initiative:', error);
      }
    );
  }
}
