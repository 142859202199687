import { Injectable } from '@angular/core';
import { Initiative } from '../model/initiative.model';
import { CrudService } from '~/commons/services';
import { environment } from '~/environments/environment';
import { Endpoint } from '~/commons/constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InitiativeService extends CrudService<Initiative> {

  protected override api = environment.apiBenef;
  protected override endpoint = Endpoint.LIST_INITIAVTIVE;

  constructor(protected override httpClient: HttpClient) {
    super(httpClient);
  }
  getInitiativeById(id: string): Observable<Initiative> {
    const url = `${this.api}/${this.endpoint}/${id}`;
    return this.httpClient.get<Initiative>(url);
  }
}
