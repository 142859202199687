import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart
} from "ng-apexcharts";
import { ToastrService } from 'ngx-toastr';
import { GetdonationsService } from '~/app/don/getdonations.service';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  theme: {
    palette: string[]; // Type de palette de couleurs
  };
};

@Component({
  selector: 'app-donations',
  templateUrl: './donations.component.html',
  styleUrls: ['./donations.component.scss']
})
export class DonationsComponent implements OnInit{
  donations: any[] = [];

  @ViewChild("chart") chart!: ChartComponent;
  public chartOptions!: ChartOptions;

  constructor(
    private getdonationservice: GetdonationsService,
    private toastr: ToastrService,
  ) { 
    this.chartOptions = {
      series: [44, 55],
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["Contribution", "Montant"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ],
      theme: {
        palette: ["#037B42", "#F9C41C"] 
      }
    };
    
  }
  ngOnInit(): void {
    this.loadDonations()
  }
  loadDonations() {
    this.getdonationservice.findAll(1, 10, '', true).subscribe(
      response => {
        this.donations = response.data;
        console.log('Response from donation endpoint:', response); 
      },
      error => {
        this.toastr.error('Erreur lors du chargement des donations');
      }
    );
  }
}
