<div id="kt_app_toolbar" class="app-toolbar d-flex pb-3 pb-lg-5">
    <!--begin::Toolbar container-->
    <div class="d-flex flex-stack flex-row-fluid">
        <!--begin::Toolbar container-->
        <div class="d-flex flex-column flex-row-fluid">
            <!--begin::Toolbar wrapper-->
            <!--begin::Page title-->
            <div class="page-title d-flex align-items-center me-3">
                <!--begin::Title-->
                <h1 class="page-heading d-flex flex-column justify-content-center text-dark fw-bold fs-lg-2x gap-2">
                    <span class="text-white">
                    <span class="fw-light text-capitalize">{{ welcomeMessage }}</span></span>
                    <!--begin::Description-->
                    <!-- <span class="page-desc text-gray-600 fs-base fw-semibold">You are logged in as a Cloud Owner</span> -->
                    <!--end::Description-->
                </h1>
                <!--end::Title-->
            </div>
            <!--end::Page title-->
        </div>
        <!--end::Toolbar container-->
        <!--begin::Actions-->
       
        <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
</div>