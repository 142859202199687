import { Component, OnInit } from '@angular/core';
import { InitiativeService } from '../service/initiative.service';
import { Initiative } from '../model/initiative.model';

@Component({
  selector: 'app-initiative-layout',
  templateUrl: './initiative-layout.component.html',
  styleUrls: ['./initiative-layout.component.scss']
})
export class InitiativeLayoutComponent  implements OnInit{
  selectedCard: number | null = null;
  initiatives: Initiative[] = [];

  constructor( private initiativeService : InitiativeService
  ) { }

  ngOnInit() {
    this.loadInitiatives()
  }

  loadInitiatives() {
    this.initiativeService.findAll(1, 10, '', true).subscribe(
      response => {
        console.log('Response from initiatives endpoint:', response); 
        this.initiatives = response.data;
       
      },)}

      selectCard(id: number | undefined): void {
        if (id !== undefined) {
          this.selectedCard = id;
        }
      }

}
