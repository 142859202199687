import { Injectable } from '@angular/core';
import { Donation } from './model/donation.model';
import { environment } from '~/environments/environment';
import { Endpoint } from '~/commons/constants';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '~/commons/services';

@Injectable({
  providedIn: 'root'
})
export class GetdonationsService  extends CrudService<Donation>{

  protected override api = environment.apiBenef;
  protected override endpoint = Endpoint.DONATION;

  constructor(protected override httpClient: HttpClient) {
    super(httpClient);
  }
}
