<app-navbar></app-navbar>
<div class="main d-flex align-items-center w-100">
    <div class="overlay w-100">
        <div class="mt-10 d-flex justify-content-center">
            <h3 class="text-secondary">Parrainage</h3>
        </div>
        <div class="mt-5 text-center d-flex justify-content-center">
            <h3 class="custom-text">Sélectionnez un type de couverture</h3>
        </div>
        
        <div class="mt-3">
            <div class="row d-flex justify-content-center align-items-center">
                <a [routerLink]="['/category', 'classique']" class="col-md-4 d-flex flex-column justify-content-center align-items-center text-decoration-none">
                    <div class="card text-center d-flex justify-content-center align-items-center p-3 w-100">
                        <h2>CMU Classique</h2>
                        <p>Pour aider un individu ou toute une famille à profiter d’une couverture santé.</p>
                        <span class="separator-dashed my-2 text-primary"></span>
                        <p class="mt-3">Coût de la cotisation individuelle ................... <span class="text-primary">3500 Fcfa</span></p>
                    </div>
                </a>
                <a [routerLink]="['/category', 'eleve']" class="col-md-4 d-flex flex-column justify-content-center align-items-center text-decoration-none">
                    <div class="card text-center p-3 w-100 justify-content-center align-items-center">
                        <h2>CMU Élèves</h2>
                        <p>Pour aider un ou plusieurs élèves à profiter d’une couverture santé à moindre coûts.</p>
                        <span class="separator-dashed my-2 text-primary"></span>
                        <p class="mt-3">Coût de la cotisation individuelle ................... <span class="text-primary">1000 Fcfa</span></p>
                    </div>
                </a>
                <a [routerLink]="['/category', 'ndongo']" class="col-md-4 d-flex flex-column justify-content-center align-items-center text-decoration-none">
                    <div class="card text-center p-3 w-100 align-items-center justify-content-center">
                        <h2>CMU Ndongo Daara</h2>
                        <p>Pour aider un ou plusieurs élèves d’écoles coraniques à profiter d’une couverture santé à moindre coûts.</p>
                        <span class="separator-dashed my-2 text-primary"></span>
                        <p class="mt-3">Coût de la cotisation individuelle ....... <span class="text-primary">1000 Fcfa</span></p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
