import { Component } from '@angular/core';

@Component({
  selector: 'app-annuairelist',
  templateUrl: './annuairelist.component.html',
  styleUrls: ['./annuairelist.component.scss']
})
export class AnnuairelistComponent {

}
