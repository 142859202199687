import { Component } from '@angular/core';
import { AuthService } from '~/app/modules/auth/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { CurrentUser } from '~/app/modules/auth/models/currentUser.model';
import { UpdateUser } from '~/app/modules/auth/models/UpdateUser.model';
import { Router } from '@angular/router';


@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent {
  source!: string;
  loading: boolean = false;
  loadingRole: boolean = false;
  avatarFile: File | null = null;
  currentUser: CurrentUser | null = null;
  otp: string = '';
  newPhoneNumber: string = '';
  newEmail: string = '';
  oldPassword!: string;
  newPassword!: string;
  newPasswordRepeat!: string;

  firstName?: string = "";
  lastName?: string = ""

  constructor(private authService: AuthService,
    private toastr: ToastrService,
    private router: Router) { }

  navigateToUserTransaction() {
    this.router.navigate(['/base/user/transaction-list']);
  }

  ngOnInit() {

    this.oldPassword = this.oldPassword;
    this.newPassword = this.newPassword;
    this.newPasswordRepeat = this.newPasswordRepeat;

    this.authService.getCurrentUser().subscribe(
      (user: CurrentUser | null) => {

        this.currentUser = user;
        if (this.currentUser) {
          this.firstName = this.currentUser.firstName;
          this.lastName = this.currentUser.lastName;
        }

      },
    );
  }


  //upadte User 
  updateUserName() {
    if (this.currentUser) {
      const updateUserData: UpdateUser = {
        firstName: this.firstName,
        lastName: this.lastName
      };
      this.authService.updateUserName(updateUserData).subscribe({
        next: response => {
          console.log('Informations mises à jour avec succès', response);
          this.currentUser!.firstName = this.firstName;
          this.currentUser!.lastName = this.lastName;
          this.toastr.success("Informations mises à jour avec succès")
          this.authService.identity(true).subscribe();
        },
        error: err => {
          console.error('Erreur lors de la mise à jour du nom', err);
          this.toastr.error("Erreur lors de la mise à jour")
        }
      }


      );
    }
  }

  updatePhoneNumberWithOTP() {
    this.authService.updatePhoneNumber({ phone: this.newPhoneNumber, otp: this.otp }).subscribe({
      next: response => {
        console.log('Numéro de téléphone mis à jour avec succès : ', response);
      },
      error: err => {
        console.error('Erreur lors de la mise à jour du numéro de téléphone : ', err);
      }
    });
  }

  //UPDATE EMAIL
  updateEmail() {
    this.authService.SendOTPToOldEmail({ email: this.newEmail }).subscribe({
      next: response => {
        //console.log('OTP envoyé avec succès : ', response); 
        this.toastr.success(`un code OTP a été envoyé à cet email${this.newEmail} , merci de consulter pour continuer`)
      },
      error: err => {
        //console.error('Erreur lors de l\'envoi de l\'OTP : ', err);
        this.toastr.error('Erreur lors de l\'envoi de l\'OTP : ')
      }
    });
  }

  updateEmailWithOTP() {
    this.authService.UpdateEmail({ email: this.newEmail, otp: this.otp }).subscribe({
      next: updateResponse => {
        //console.log('Email mis à jour avec succès : ', updateResponse);
        this.toastr.success('Email mis à jour avec succès')
      },
      error: updateError => {
        console.error('Erreur lors de la mise à jour de l\'email : ', updateError);
      }
    });
  }

  //update password 
  updatePassword() {
    if (this.newPassword !== this.newPasswordRepeat) {
      //console.error('Les nouveaux mots de passe ne correspondent pas.');
      this.toastr.error('Les deux mots de passe ne correspondent pas.')
      return

    }

    this.authService.ChangePassword({
      oldPassword: this.oldPassword,
      newPassword: this.newPassword,
      newPasswordRepeat: this.newPasswordRepeat
    }).subscribe({
      next: response => {
        console.log('Mot de passe mis à jour avec succès : ', response);
        this.toastr.success("Mot de passe mis à jour avec succès")
      },
      error: err => {
        console.error('Erreur lors de la mise à jour du mot de passe : ', err);
        this.toastr.error("Erreur lors de la mise à jour du mot de passe")
      }
    });
  }

  //upadte avatar
  fileChangeListener($event: any) {
    const file = $event.target.files[0];
    if (file) {
      this.avatarFile = file;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.source = reader.result as string;
      };
    }
  }
  resetImage() {
    this.source = '';
  }
  updateProfilePicture() {
    if (this.source && this.currentUser) {
      const payload = {
        content: this.source 
      };

      this.loading = true;
      this.authService.updateProfilePicture(payload).subscribe({
        next: response => {
          //console.log('Photo de profil mise à jour avec succès', response);
          this.toastr.success("Photo de profil mise à jour avec succès");
          
          if (this.currentUser) {
            this.currentUser.avatarUrl = response.avatarUrl;
            this.authService.setCurrentUser(this.currentUser);
          }
          this.loading = false;
          //this.resetImage();
        },
        error: err => {
          //console.error('Erreur lors de la mise à jour de la photo de profil', err);
          this.toastr.error("Erreur lors de la mise à jour de la photo de profil");
          this.loading = false;
        }
      });
    }
  }
  

}

