<div class="popup-overlay">
    
    <div class="popup-content">
      
      <h2>Statut de ma couverture</h2>
      <hr>
      <button class="btn btn-primary" (click)="navigateToMembership()"> Je suis pas encore assuré <br> ADHERER A UN MUTUELLE</button>
      <button class="btn btn-secondary" (click)="navigateToLinkCode()">Je suis déjà assuré par une mutuelle <br> LIER MON CODE D'ASSURE</button>
    </div>
  </div>
  