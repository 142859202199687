import { Component } from '@angular/core';
import { TranslationService } from './modules/i18n';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { locale as enLang } from './modules/i18n/vocabs/en';

@Component({
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'skeleton_angular';

  constructor(
    private translationService: TranslationService
  ){
    this.translationService.loadTranslations(
      frLang,
      enLang
    );
  }
}
