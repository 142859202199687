import { AfterViewInit, Component, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss']
})
export class BaseLayoutComponent implements AfterViewInit {
  constructor(private renderer: Renderer2) { }
  ngAfterViewInit(): void {
    const script = this.renderer.createElement('script');
    script.src = 'assets/js/scripts.bundle.js';
    this.renderer.appendChild(document.body, script);
  }
}
