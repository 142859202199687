import { Observable } from "rxjs";
import { ICrudService } from "./icrud.service";
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from "../entities";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export abstract class CrudService<T> implements ICrudService<T>{
    protected api: string = environment.apiAuth;
    protected endpoint!: string;
    
    constructor(protected httpClient: HttpClient){}

    /**
     * 
     * @param where 
     * @returns 
     */
    export(where?: string | undefined): Observable<HttpResponse<Blob>> {
        return this.httpClient.get(`${this.api}${this.endpoint}`,{observe: 'response', responseType: 'blob'});
    }
    
    /**
     * 
     * @param id the id of the resource to find
     * @returns the resource with the given id
     */
    findById(id: number): Observable<T> {
        return this.httpClient.get<T>(`${this.api}${this.endpoint}/${id}`);
    }

    /**
     * 
     * @param data the resource to create
     * @returns the newly created resource
     */
    create(data: Omit<T,'id'>): Observable<T> {
        return this.httpClient.post<T>(`${this.api}${this.endpoint}`,data);
    }

    /**
     * 
     * @param id the id of the resource to update
     * @param data the resource to update
     * @returns 
     */
    update(id: number, data: T): Observable<T> {
        return this.httpClient.patch<T>(`${this.api}${this.endpoint}/`+id,data);
    }

    /**
     * 
     * @param id the id of the resource to update
     * @param data the resource to update
     * @returns 
     */
     updatePartial(id: number, data: Partial<T> & Omit<T,'id'>): Observable<T> {
        return this.httpClient.patch<T>(`${this.api}${this.endpoint}/`+id,data);
    }

    /**
     * 
     * @param id the id of the resource to delete
     * @returns 
     */
    delete(id: number): Observable<T> {
        return this.httpClient.delete<T>(`${this.api}${this.endpoint}/`+id);
    }

    /**
     * 
     * @param relations 
     * @param where 
     * @returns 
     */
    findOne(relations: string, where: string): Observable<T> {
        let params= {
            relations,
            where
        }

        return this.httpClient.get<T>(`${this.api}${this.endpoint}/findOne`,{params});
    }

    /**
     * 
     * @param page 
     * @param size 
     * @param search 
     * @param pageable 
     * @param isDeleted 
     * @returns 
     */
    findAll(page: number, size: number, search: string, pageable: boolean, isDeleted = false): Observable<ApiResponse<T>> {
        const params = {
            page,
            size,
            pageable,
            isDeleted,
            ...(search && { search }),
        };


        return this.httpClient.get<ApiResponse<T>>(`${this.api}${this.endpoint}`,{params});
    }

}