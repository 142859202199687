import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  private qrCode: string | null = null;

  setQrCode(qrCode: string) {
    this.qrCode = qrCode;
  }

  getQrCode(): string | null {
    return this.qrCode;
  }
}
