import { Component } from '@angular/core';

@Component({
  selector: 'app-sponsorship-ndongo-complement-packet',
  templateUrl: './sponsorship-ndongo-complement-packet.component.html',
  styleUrls: ['./sponsorship-ndongo-complement-packet.component.scss']
})
export class SponsorshipNdongoComplementPacketComponent {

}
