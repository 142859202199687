import { Component } from '@angular/core';
import { AuthService } from '~/app/modules/auth/services/auth.service';
import { Router } from '@angular/router';
import { CurrentUser } from '~/app/modules/auth/models/currentUser.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent { 
  currentUser: CurrentUser | null = null;
  constructor(private authService: AuthService ,
    private router: Router) { }

    ngOnInit() {
      this.authService.getCurrentUser().subscribe(
        (user: CurrentUser | null) => {
          
          this.currentUser = user;
          if (this.currentUser) {
            //console.log('Informations utilisateur :', this.currentUser);
          }
        
        },
        (error: any) => {
          //console.error('Erreur lors de la récupération des informations de l\'utilisateur :', error);
        }
      );
    }

  logout(): void {
    this.authService.logout();
    console.log("deconnexion reussi")
    this.router.navigate(['/']);
  }
}
