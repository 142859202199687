<div class="container-fluid" id="block_annuaire">
    <div class="row justify-content-center">
        <div class="col-lg-8 text-center">
            <h2>Liste des annuaires de <span class="sunu">SUNU</span> <span class="csu">CSU</span></h2>
            <div>
                <p class="mt-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi, .</p>
            </div>
        </div>
    </div>


    <div class="box">
        <div class="box1">
            <div class="annuaire">
                <div >
                    <img src="../../../assets/images/medecin.png" alt="" srcset="">
                </div>
                <div class="div1">
                    <h1>Médecin analystes</h1>
                    <p>lorem</p>
                </div>

            </div>
        </div>
        <div class="box1">
            <div class="annuaire">
                <div >
                    <img src="../../../assets/images/Phar.png" alt="" srcset="">
                </div>
                <div  class="div1">
                    <h1>Pharmacies</h1>
                <p>Lorem</p>
                </div>
                
            </div>
        </div>
        <div class="box1">
            <div class="annuaire">
                <div >
                    <img src="../../../assets/images/assureur.png" alt="" srcset="">
                </div>
                <div class="div1">
                    <h1>Assureurs</h1>
                <p>Lorem</p>
                </div>
                
            </div>
        </div>
    </div>
    <div class="box2">
        <div class="box1">
            <div class="annuaire">
                <div >
                    <img src="../../../assets/images/Bure.png" alt="" srcset="">
                </div>
                <div class="div1">
                    <h1>Bureau CMU</h1>
                <p>lorem</p>
                </div>
                
            </div>
        </div>
        <div class=" box1">
            <div class="annuaire">
                <div >
                    <img src="../../../assets/images/point.png" alt="" srcset="">
                </div>
                <div class="div1">
                    <h1>Points de prestations</h1>
                <p>Lorem</p>
                </div>
                
            </div>
        </div>
        <div class="box1">
            <div>

            </div>
        </div>

    </div>
</div>