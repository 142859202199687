<div class="container-fluid">
    <div class="row justify-content-center"> 
        <div class="col-lg-8 text-center"> 
            <h2>BIENVENUE DANS NOTRE PLATEFORME <span class="sunu">SUNU</span> <span class="csu">CSU</span></h2>
            <div>
                <p class="mt-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi,  .</p>
            </div>
        </div>
        <div class="imageBlock">
            <img src="../../../assets/images/Group (1).png"  alt="" srcset="">
        </div>
    </div>
</div>
