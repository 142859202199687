

import { Component, OnInit } from '@angular/core';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { BeneficiaryModel, AdhesionType } from '../models/beneficiary.model';
import { BeneficiaryService } from '../services/beneficiary.service';
import { PaymentMethodeService } from '~/app/modules/auth/services/payment-methode.service';
import { ToastrService } from 'ngx-toastr';
import { RelationService } from '../services/relation.service';
import { RegionModel } from '~/app/mutuels/models/regions.model';
import { RelationModel } from '../models/relation.model';
import { MembershipServiceService } from '../services/membership-service.service';
import { Membership, MembershipResponse } from '../models/membership.model';
import { Subscription } from 'rxjs';
import { SharedDataService } from '../services/shared-data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-benefeciary',
  templateUrl: './add-benefeciary.component.html',
  styleUrls: ['./add-benefeciary.component.scss']
})
export class AddBenefeciaryComponent implements OnInit {
  phone: string = '';
  SearchCountryField = SearchCountryField;
  selectedC = CountryISO.Senegal;
  structureId: number | null = null;
  mutuelleName: string = '';
  mutuelleNumber: string = '';
  beneficiaryName: string = '';
  beneficiaryRelation: string = '';
  currentStep: number = 1;
  paymentMethodId: number = 1;
  recipientPhoneNumber: string = '';
  newBeneficiary: BeneficiaryModel = {
    paymentMethodId: 0,
    recipientPhoneNumber: '',
    cardNumber: '',
    cardExpireYear: '',
    cardExpireMonth: '',
    cvv: '',
    cardHolderName: '',
    clientFirstname: '',
    clientLastname: '',
    beneficiary: {
      id: 1,
      firstName: '',
      lastName: '',
      phone3: '',
      birthPlace: '',
      birthDate: "",
      IdType: '',
      IdNumber: '',
      address: '',
      sex: 'MASCULIN',
      email: '',
      mutualId: 0,
      beneficiaryTypeId: 0,
      membershipType: AdhesionType.Famille,
      membership: {
        id: 1
      },
      idNumber: '',
      idType: ''
    },
  };

  telephone!: any;
  phone3!: any;
  phone2!: any;
  totalAmount: number = 0;
  readonly COST_PER_MEMBER: number = 3500;
  relations: RelationModel[] = [];

  membership: Membership | null = null;
  loading = false;
  error: string | null = null;
  private membershipSubscription!: Subscription;
  

  selectedPaymentMethod: string = '';
  paymentMethods: { name: string, image: string, commissionRate: number, totalAmount: string, paymentMethodId: number, categorie: string , nom:string }[] = [];

  constructor(
    private beneficiaryService: BeneficiaryService,
    private paymentMethodeService: PaymentMethodeService,
    private toastr: ToastrService,
    private relationService: RelationService,
    private membershipService: MembershipServiceService,
    private sharedDataService: SharedDataService,
    private router : Router,
  ) { }

  ngOnInit() {
    this.loadPaymentMethods();
    this.loadRelations();
    this.loadMembership();
  }

  ngOnDestroy(): void {
    if (this.membershipSubscription) {
      this.membershipSubscription.unsubscribe();
    }
  }

  loadMembership(): void {
    this.membershipService.getMembership().subscribe({
      next: (response: MembershipResponse) => {
        if (response.data && response.data.length > 0) {
          this.membership = response.data[0];
        } else {
          console.error('No membership data found');
        }
      },
      error: (error) => {
        console.error('Error loading membership:', error);
      }
    });
  }

  loadRelations () {
    this.relationService.findAll(1,10,'', true).subscribe(
      response=> {
        //console.log('response from relation endpoint:', response);
        this.relations = response.data;
      }
    )
  }

  loadPaymentMethods() {
    this.paymentMethodeService.findAll(0, 10, '', true).subscribe(
      response => {
        console.log('Response from payment methods endpoint:', response);
        this.paymentMethods = response.data.map(paymentMethod => ({
          name: paymentMethod.operateur.nom,
          image: paymentMethod.operateur.urlLogo,
          commissionRate: paymentMethod.taux,
          totalAmount: '0000 Cfa',
          paymentMethodId: paymentMethod.id,
          categorie: paymentMethod.categorie,
          nom: paymentMethod.nom,
        }));
      },
      error => {
        this.toastr.error('Erreur lors du chargement des méthodes de paiement');
      }
    );
  }

  selectPhone3(event: any) {
    if (event && event.e164Number) {
      this.newBeneficiary.beneficiary.phone3 = event.e164Number;
    }
  }

  selectPhone(event: any) {
    if (event && event.e164Number) {
      this.phone = event.e164Number;
      this.newBeneficiary.recipientPhoneNumber = this.phone; 
    }
    
  }
  

  selectPaymentMethod(method: string): void {
    this.selectedPaymentMethod = method;
    const selectedMethod = this.paymentMethods.find(m => m.name === method);
    if (selectedMethod) {
      this.newBeneficiary.paymentMethodId = selectedMethod.paymentMethodId;
    }
  }

  getSelectedPaymentImage(): string {
    const method = this.paymentMethods.find(m => m.name === this.selectedPaymentMethod);
    return method ? method.image : '';
  }

  showCardFields(): boolean {
    const selectedMethod = this.paymentMethods.find(m => m.name === this.selectedPaymentMethod);
    return selectedMethod ? selectedMethod.categorie === 'card' : false;
  }

  goToNextStep1() {
    if (this.currentStep < 2) {
      // Votre logique pour aller au prochain étape
    }
  }

  goToNextStep2() {
    if (this.currentStep < 3) {
      this.currentStep++;
    }
  }

  goToPreviousStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  goToStep(step: number) {
    this.currentStep = step;
  }

  // submitForm() {
  //   console.log('Form data before submission:', this.newBeneficiary);

  //   const selectedMethod = this.paymentMethods.find(m => m.name === this.selectedPaymentMethod);

  //   const payload: any = {
  //     paymentMethodId: this.newBeneficiary.paymentMethodId,
  //     recipientPhoneNumber: this.newBeneficiary.recipientPhoneNumber,
  //     firstName: this.newBeneficiary.beneficiary.firstName,
  //     lastName: this.newBeneficiary.beneficiary.lastName,
  //     phone3: this.newBeneficiary.beneficiary.phone3,
  //     birthPlace: this.newBeneficiary.beneficiary.birthPlace,
  //     birthDate: this.newBeneficiary.beneficiary.birthDate,
  //     IdType: 1,
  //     IdNumber: 1,
  //     address: this.newBeneficiary.beneficiary.address,
  //     sex: this.newBeneficiary.beneficiary.sex,
  //     email: '',
  //     mutualId: 1,
  //     beneficiaryTypeId: 1
  //   };

  //   // Ajoutez les détails de la carte si la catégorie est 'card'
  //   if (selectedMethod?.categorie === 'card') {
  //     if (!this.newBeneficiary.cardNumber || !this.newBeneficiary.cvv || !this.newBeneficiary.cardExpireYear || !this.newBeneficiary.cardExpireMonth || !this.newBeneficiary.cardHolderName || !this.newBeneficiary.clientFirstname || !this.newBeneficiary.clientLastname) {
  //       this.toastr.error('Veuillez remplir tous les champs de la carte avant de soumettre.');
  //       return;
  //     }

  //     payload.cardDetails = {
  //       cardNumber: this.newBeneficiary.cardNumber,
  //       cardExpireYear: this.newBeneficiary.cardExpireYear,
  //       cardExpireMonth: this.newBeneficiary.cardExpireMonth,
  //       cvv: this.newBeneficiary.cvv,
  //       cardHolderName: this.newBeneficiary.cardHolderName,
  //       clientFirstname: this.newBeneficiary.clientFirstname,
  //       clientLastname: this.newBeneficiary.clientLastname
  //     };
  //   }

  //   console.log('Payload:', payload);

  //   this.beneficiaryService.submitBeneficiary(payload).subscribe(
  //     response => {
  //       // if (response.launchUrl) {
  //       //   this.toastr.success("Payment initié avec succès");
  //       //   window.location.href = response.launchUrl;
  //       // } else {
  //       //   this.toastr.success("Beneficiary submitted successfully");
  //       return
  //       if (selectedMethod?.nom === 'Orange Money') {
  //         if (response.qrCode) {
  //           this.sharedDataService.setQrCode(response.qrCode);
  //           this.router.navigate(['/scanner']);
  //         } else {
  //           this.toastr.error('QR Code manquant pour Orange Money');
  //         }
  //       } else if (response.launchUrl) {
  //         window.location.href = response.launchUrl;
  //       } else {
  //         this.toastr.error('URL de lancement manquante');
  //       }
  //     },
  //     error => {
  //       this.toastr.error("Error during beneficiary submission");
  //     }
  //   );
  // }
  submitForm() {
    console.log('Form data before submission:', this.newBeneficiary);
  
    const selectedMethod = this.paymentMethods.find(m => m.name === this.selectedPaymentMethod);
  
    const payload: any = {
      paymentMethodId: this.newBeneficiary.paymentMethodId,
      recipientPhoneNumber: this.newBeneficiary.recipientPhoneNumber,
      firstName: this.newBeneficiary.beneficiary.firstName,
      lastName: this.newBeneficiary.beneficiary.lastName,
      phone3: this.newBeneficiary.beneficiary.phone3,
      birthPlace: this.newBeneficiary.beneficiary.birthPlace,
      birthDate: this.newBeneficiary.beneficiary.birthDate,
      IdType: 1,
      IdNumber: 1,
      address: this.newBeneficiary.beneficiary.address,
      sex: this.newBeneficiary.beneficiary.sex,
      email: '',
      mutualId: 1,
      beneficiaryTypeId: 1
    };
  
    // Ajoutez les détails de la carte si la catégorie est 'card'
    if (selectedMethod?.categorie === 'card') {
      if (!this.newBeneficiary.cardNumber || !this.newBeneficiary.cvv || !this.newBeneficiary.cardExpireYear || !this.newBeneficiary.cardExpireMonth || !this.newBeneficiary.cardHolderName || !this.newBeneficiary.clientFirstname || !this.newBeneficiary.clientLastname) {
        this.toastr.error('Veuillez remplir tous les champs de la carte avant de soumettre.');
       
      }
  
      payload.cardDetails = {
        cardNumber: this.newBeneficiary.cardNumber,
        cardExpireYear: this.newBeneficiary.cardExpireYear,
        cardExpireMonth: this.newBeneficiary.cardExpireMonth,
        cvv: this.newBeneficiary.cvv,
        cardHolderName: this.newBeneficiary.cardHolderName,
        clientFirstname: this.newBeneficiary.clientFirstname,
        clientLastname: this.newBeneficiary.clientLastname
      };
    }
  
    //console.log('Payload:', payload); // Vérifiez ici que recipientPhoneNumber est présent
  
    this.beneficiaryService.submitBeneficiary(payload).subscribe(
      response => {
        
        if (selectedMethod?.nom === 'Orange Money') {
          if (response.qrCode) {
            this.sharedDataService.setQrCode(response.qrCode);
            this.router.navigate(['/scanner']);
          } else {
            this.toastr.error('QR Code manquant pour Orange Money');
          }
        } else if (response.launchUrl) {
          window.location.href = response.launchUrl;
        } else {
          this.toastr.error('URL de lancement manquante');
        }
      },
      error => {
        this.toastr.error("Error during beneficiary submission");
      }
    );
  }
  
}

