import { Component } from '@angular/core';

@Component({
  selector: 'app-sponsorship-ndongo-base-packet',
  templateUrl: './sponsorship-ndongo-base-packet.component.html',
  styleUrls: ['./sponsorship-ndongo-base-packet.component.scss']
})
export class SponsorshipNdongoBasePacketComponent {

}
