import { Injectable } from '@angular/core';
import { CrudService } from '~/commons/services';
import { RegionModel } from '../models/regions.model';
import { environment } from '~/environments/environment';
import { Endpoint } from '~/commons/constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegionsService extends CrudService<RegionModel> {

  protected override api = environment.apiBenef;
  protected override endpoint = Endpoint.LIST_REGIONS;

  constructor(protected override httpClient: HttpClient) {
    super(httpClient);
  }

  findMutuellesByRegion(regionId: number): Observable<any> {
    return this.httpClient.get(`${this.api}/gestam/mutuals/findByregion/${regionId}`);
  }
}
