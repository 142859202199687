
export enum AdhesionType {
    Famille = 'FAMILLE',
    Individuel = 'INDIVIDUEL'
}

export interface Beneficiary {
    id?: number;
    dateAdhesion?: string
    firstName: string;
    lastName: string;
    phone3: string;
    birthPlace: string;
    birthDate: string;
    IdType: string;
    IdNumber: string;
    address: string;
    sex: 'MASCULIN' | 'FEMININ';
    email: string;
    mutualId: number;
    beneficiaryTypeId: number;
    membershipType: AdhesionType
    membership?: {
        id: number;
    };
    idNumber: string;
    idType: string;
    benefStatus?: string;
    extCode?: string;
    createdAt?: string;
    code?: string;
    houseHolder?: boolean;
    qrCodeUrl?: string;



}

export interface BeneficiaryModel {
    paymentMethodId: number;
    recipientPhoneNumber?: string,
    cardNumber?: string;
    cardExpireYear?: string;
    cardExpireMonth?: string;
    cvv?: string;
    cardHolderName?: string;
    clientFirstname?: string;
    clientLastname?: string;
    beneficiary: Beneficiary;
    beneficiaries?: Beneficiary[];
    mutual?: { id: number, basicInfo: { libelle: string } }; 

}

export class ApiResponse<T> {
    data: T[] = [];
    totalItems!: number;
    totalPages!: number;
}