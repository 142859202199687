<div id="kt_app_content" class="app-content flex-column-fluid bg-transparent">
    <div class="card ">
        <div class="card-body pt-0">
            <div id="kt_customers_table_wrapper" class="dt-container dt-bootstrap5 dt-empty-footer">
                <div class="table-responsive ">
                    <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable w-100" id="kt_customers_table">
                        <thead class="w-100">
                            <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0 w-100">
                                <th class="min-w-125px dt-orderable-asc dt-orderable-desc bg-primary w-50 ms-2">
                                    <span class="dt-column-title text-center text-white ms-4">Nom(s) & Prénom(s)</span>
                                </th>
                                <th class="min-w-125px dt-orderable-asc dt-orderable-desc bg-primary">
                                    <span class="dt-column-title text-center text-white">Code d’assuré</span>
                                </th>
                                <th class="min-w-125px dt-orderable-asc dt-orderable-desc bg-primary w-50">
                                    <span class="dt-column-title text-center text-white">Date d'adhesion</span>
                                </th>
                                <th class="min-w-125px dt-orderable-asc dt-orderable-desc bg-primary w-100">
                                    <span class="dt-column-title text-center text-white">Mutuelle</span>
                                </th>
                                <th class="min-w-125px dt-orderable-asc dt-orderable-desc bg-primary">
                                    <span class="dt-column-title text-center text-white">Etat</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="fw-semibold text-gray-600 bg-white">
                            <tr *ngFor="let beneficiary of beneficiaries">
                              <td><span class="ms-4">{{ beneficiary.firstName || '-' }} {{ beneficiary.lastName || '-' }}</span></td>
                              <td>{{ beneficiary.extCode || '-' }}</td>
                              <td>{{ beneficiary.createdAt ? (beneficiary.createdAt | date: 'dd/MM/yyyy') : '-' }}</td>
                              <!-- <td>{{ getCurrentDateFormatted() }}</td> -->
                            <td>{{ getMutuelleName(beneficiary.mutualId) }}</td>
                              <td class="text-red d-flex justify-content-center">
                                <span class="badge badge-light-warning">
                                    {{ beneficiary.benefStatus }}</span>
                              </td>
                            </tr>
                          </tbody>
                          
                    </table>
                </div>
                <!-- <div class="row">
                    <div class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                        <span class="text-muted">Total: {{ totalItems }}</span>
                    </div>
                    <div class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end mt-10">
                      <pagination-controls 
                          previousLabel="" 
                          nextLabel="" 
                          (pageChange)="paginate($event)">
                      </pagination-controls>
                  </div>
                  
                </div> -->
            </div>
        </div>
    </div>
  </div> 
   
  