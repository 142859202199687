<div class="container1">
  <div class="separator separator-dotted separator-content border-dark my-15">
    <h1 class="">Quelques un de nos partenaires</h1>
  </div>  
    <div class="horizontal-list">
      <div class="item"> <h1>Partenaire</h1></div>
      <div class="item"><h1>Partenaire</h1></div>
      <div class="item"><h1>Partenaire</h1></div>
      <div class="item"><h1>Partenaire</h1></div>
      <div class="item"><h1>Partenaire</h1></div>
      <div class="item"><h1>Partenaire</h1></div>
    </div>
  </div>
  