
<app-navbar></app-navbar>
<div class="main d-flex justify-content-center align-items-center">
  <div class="container d-flex flex-column justify-content-center  w-100">
    <div class="d-flex flex-row justify-content-evenly text-primary">
      <div class="d-flex flex-column">
        <p><strong>Montant à payer:</strong> {{ nbBeneficiaries * amount }} FCFA</p>
      </div>
    </div>

    <hr>

    <h2 class="text-primary text-center">Choisissez un moyen de paiement</h2>
    <div class="payment-methods row">
      <div *ngFor="let method of paymentMethods" (click)="selectPaymentMethod(method.name)" class="col-12 col-md-3 p-2">
        <div [ngClass]="{'payment-card': true, 'selected-card': selectedPaymentMethod === method.name}" class="card h-100">
          <img [src]="method.image" alt="{{ method.name }}" class="card-img-top">
        </div>
      </div>
    </div>

    <hr>

    <div class="selected-payment-method" *ngIf="selectedPaymentMethod">
      <h4 class="text-primary text-center">
        Paiement sécurisé avec 
        <img [src]="getSelectedPaymentImage()" alt="{{ selectedPaymentMethod }}" style="width: 70px; height: auto; vertical-align: middle;">
      </h4>
      
      <div class="form-group w-100" *ngIf="!showCardFields()">
        <label for="phone" class="text-primary">Téléphone</label>
        <input type="text" class="form-control" id="phone"
            [(ngModel)]="phone"
            placeholder="Veuillez saisir votre numéro de téléphone">
    </div>

    <!-- Show card fields if payment method is card -->
    <div *ngIf="showCardFields()">
        <div class="d-flex w-100">
            <div class="form-group w-100 me-5">
                <label for="cardNumber" class="text-primary">Numéro de
                    carte</label>
                <input type="text" class="form-control" id="cardNumber"
                    [(ngModel)]="cardNumber" placeholder="Numéro de carte">
            </div>
            <div class="form-group w-100 me-5">
                <label for="cardExpireYear" class="text-primary">Année
                    d'expiration</label>
                <input type="text" class="form-control" id="cardExpireYear"
                    [(ngModel)]="cardExpireYear" placeholder="AAAA">
            </div>
        </div>

        <div class="d-flex w-100">
            <div class="form-group w-100 me-5">
                <label for="cardExpireMonth" class="text-primary">Mois
                    d'expiration</label>
                <input type="text" class="form-control" id="cardExpireMonth"
                    [(ngModel)]="cardExpireMonth" placeholder="MM">
            </div>
            <div class="form-group w-100 me-5">
                <label for="cvv" class="text-primary">CVV</label>
                <input type="text" class="form-control" id="cvv"
                    [(ngModel)]="cvv" placeholder="CVV">
            </div>
        </div>
        <div class="d-flex w-100">
            <div class="form-group w-100 me-5">
                <label for="cardHolderName" class="text-primary">Nom du
                    titulaire</label>
                <input type="text" class="form-control" id="cardHolderName"
                    [(ngModel)]="cardHolderName"
                    placeholder="Nom du titulaire">
            </div>
            <div class="form-group w-100 me-5">
                <label for="clientFirstname" class="text-primary">Prénom du
                    client</label>
                <input type="text" class="form-control" id="clientFirstname"
                    [(ngModel)]="clientFirstname" placeholder="Prénom">
            </div>
        </div>

        <div class="d-flex w-100">
            <div class="form-group w-100 me-5">
                <label for="clientLastname" class="text-primary">Nom du
                    client</label>
                <input type="text" class="form-control" id="clientLastname"
                    [(ngModel)]="clientLastname" placeholder="Nom">
            </div>
            <div class="form-group w-100 me-5">

            </div>
        </div>
    </div>
    </div>

    <div class="buttons d-flex justify-content-end mt-4">
      <button class="btn btn-primary ms-4" (click)="validatePayment()">Valider</button>
      <button class="btn btn-secondary ms-8" (click)="cancelPayment()">Annuler</button>
    </div>
  </div>
</div>
<app-footer></app-footer>

