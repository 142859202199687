import { Injectable } from '@angular/core';
import { Annuaire } from '../models/annuaire.model';
import { CrudService } from '~/commons/services';
import { environment } from '~/environments/environment';
import { Endpoint } from '~/commons/constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnnuaireService {

  constructor(
    private httpClient: HttpClient
) {
}

  getStructures(search: any): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiBenef}${Endpoint.LIST_STRUCTURES}` +  this.getSearchValues(search))
  }

  getAllRegions(){
    return this.httpClient.get<any>(`${environment.apiBenef}${Endpoint.LIST_REGIONS}`)
  }

  public getIPAddress() {
    return this.httpClient.get("http://api.ipify.org/?format=json");
  }

  public getInfoVille(ip:any) {
    return this.httpClient.get("http://ip-api.com/json/" + ip);
  }

  getDepsByRegion(regionId:any){
    return this.httpClient.get<any>(`${environment.apiBenef}${Endpoint.LIST_DEPT_BY_REGIONS}/${regionId}`)
  }

  getSearchValues(values:any){
    let urlpath = '?page=' + values.page + '&codeType=' + values.codeType + '&pageable=true';
    if (values.departement && values.departement !='aucun'){
      urlpath += '&departement=' + values.departement
    }
    if (values.region){
      urlpath += '&region=' + values.region
    }
    if (values.size){
      urlpath += '&size=' + values.size
    }
    return urlpath;
  }
}
