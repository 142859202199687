import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '~/environments/environment';
import { Endpoint } from '~/commons/constants';
import { BeneficiaryModel, Beneficiary, AdhesionType } from '../models/beneficiary.model'; // Assurez-vous que le chemin est correct

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryService {
  constructor(private http: HttpClient) { }

  submitBeneficiary(beneficiaryData: any): Observable<any> {
    const beneficiaryPayload = this.formatBeneficiaryData(beneficiaryData);

    return this.http.post<BeneficiaryModel>(`${environment.apiBenef}${Endpoint.ADD_BENEFICIARY}`, beneficiaryPayload).pipe(
      catchError(error => {
        console.error('Error during beneficiary submission:', error);
        return throwError(error);
      }),
      map(response => {
        return response;
      })
    );
  }

  private formatBeneficiaryData(data: any): BeneficiaryModel {
    return {
      paymentMethodId: data.paymentMethodId || 1,
      recipientPhoneNumber: data.recipientPhoneNumber || '',
      cardNumber: data.cardNumber || '',
      cardExpireYear: data.cardExpireYear || '',
      cardExpireMonth: data.cardExpireMonth || '',
      cvv: data.cvv || '',
      cardHolderName: data.cardHolderName || '',
      clientFirstname: data.clientFirstname || '',
      clientLastname: data.clientLastname || '',
      beneficiary: {
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        phone3: data.phone3 || '',
        birthPlace: data.birthPlace || '',
        birthDate: data.birthDate,
        IdType: data.IdType || '',
        IdNumber: data.IdNumber || '',
        address: data.address || '',
        sex: data.sex || 'MASCULIN',
        email: data.email || '',
        mutualId: data.mutualId || 1,
        beneficiaryTypeId: data.beneficiaryTypeId || 1,
        membershipType: data.membershipType || AdhesionType.Famille,
        membership: {
          id: data.membership?.id
        },
        idNumber: data.idNumber || '',
        idType: data.idType || ''
      }
    };
  }
}
