import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RegionModel } from '~/app/mutuels/models/regions.model';
import { RegionsService } from '~/app/mutuels/services/regions.service';
import { DataService } from '~/app/sponsorship/data.service';
import { DonationService } from '../donation.service';
import { Donation } from '../model/donation.model';


@Component({
  selector: 'app-mutuelle-donation',
  templateUrl: './mutuelle-donation.component.html',
  styleUrls: ['./mutuelle-donation.component.scss']
})
export class MutuelleDonationComponent implements OnInit{

  regions: RegionModel[] = [];
  selectedRegion: RegionModel | null = null;
  mutuelles: any[] = [];
  selectedMutuelleId: number | null = null;
  defaultRegionName: string = 'LOUGA';
  donationAmount: number = 0;

  constructor(
    private dataService: DataService,
    private router: Router, 
    private toastr: ToastrService,
    private regionService: RegionsService,
    private donationService: DonationService
    
  ) { }

  ngOnInit() {
    this.loadRegions();
  }

  loadRegions() {
    this.regionService.findAll(1, 10, '', true).subscribe(
      response => {
        console.log('Response from regions endpoint:', response); 
        this.regions = response.data;
        this.setDefaultRegion();
      },
      error => {
        console.error('Error loading regions:', error);
        this.toastr.error('Erreur lors du chargement des régions');
      }
    );
  }

  setDefaultRegion() {
    this.selectedRegion = this.regions.find(region => region.libelle === this.defaultRegionName) || null;
    if (this.selectedRegion) {
      this.onRegionSelect();
    }
  }

  onRegionSelect() {
    if (this.selectedRegion) {
      const regionId = this.selectedRegion.id;
      this.regionService.findMutuellesByRegion(regionId).subscribe(
        response => {
          console.log('Response from mutuelles endpoint:', response);
          this.mutuelles = response.data;
        },
        error => {
          console.error('Error loading mutuelles:', error);
          this.toastr.error('Erreur lors du chargement des mutuelles');
        }
      );
    }
  }

  passerAuPaiement() {
    if (this.selectedMutuelleId && this.donationAmount > 0) {
      const donation: Donation = {

        id: 0, 
        amount: this.donationAmount,
        mutualId: this.selectedMutuelleId
      };

      this.donationService.createDonation(donation).subscribe(
        (response) => {
          console.log('Donation created:');
          this.router.navigate(['/payment-initiative'], { queryParams: { donationId: response.id, mutualId: this.selectedMutuelleId } });
        },
        (error) => {
          console.error('Error creating donation:', error);
          this.toastr.error('Erreur lors de la création du don');
        }
      );
    } else {
      this.toastr.warning('Veuillez sélectionner une mutuelle et saisir un montant valide');
    }
  
   
  }

}
