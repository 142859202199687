import { environment } from "src/environments/environment";
const env: string = environment.appName;
export const Global = {
    JWT_TOKEN: `${env}_jwt_token`,
    CURRENT_USER: `${env}_current_user`,
    CURRENT_USER_ROLES: `${env}_current_user_roles`,
    CURRENT_USER_MODULES: `${env}_current_user_modules`,
    CURRENT_USER_PERMISSIONS: `${env}_current_user_permissions`,
    ACCEPT_LANGUAGE: `${env}_accept-language`,
    LANG_KEY: `${env}_language`,
}