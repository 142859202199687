// Localization is based on '@ngx-translate/core';
// Please be familiar with official documentations first => https://github.com/ngx-translate/core

import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Global } from '~/commons/constants';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';

export interface Locale {
  lang: string;
  data: any;
}

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  // Private properties
  private langIds: string[] = [];

  constructor(private translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('fr');
  }

  /**
   * Add a set of languages to translate to
   * @param args a list of language translations
   */
  loadTranslations(...args: Locale[]): void {
    const locales = [...args];

    locales.forEach((locale) => {
      // use setTranslation() with the third argument set to true
      // to append translations instead of replacing them
      this.translate.setTranslation(locale.lang, locale.data, true);
      this.langIds.push(locale.lang);
    });

    // add new languages to the list
    this.translate.addLangs(this.langIds);

    // using the browser's language as default lang of fall back to fr
    const language = <string>this.translate.getBrowserLang();
    if(this.langIds.some(lang => lang === language)){
      this.translate.setDefaultLang(language);
      this.registerLocale(language);
    }else{
      this.translate.setDefaultLang('fr');
      this.registerLocale('fr');
    }
    
    this.translate.use(this.getSelectedLanguage());
  }

  /**
   * Sets the language to the to given id
   * @param lang the language id
   */
  setLanguage(lang: string) {
    if (lang) {
      this.translate.use(this.translate.getDefaultLang());
      this.translate.use(lang);
      localStorage.setItem(Global.LANG_KEY, lang);
      this.registerLocale(lang);
    }
  }

  registerLocale(lang: string){
    switch (lang) {
      case 'fr':
        registerLocaleData(localeFr);
        break;
      case 'en':
        registerLocaleData(localeEn);
        break;
    
      default:
        break;
    }
  }

  /**
   * Returns selected language
   */
  getSelectedLanguage(): any {
    return (
      localStorage.getItem(Global.LANG_KEY) || this.translate.getDefaultLang()
    );
  }
}
