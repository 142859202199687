import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { Family } from '../models/sponsorship.model';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-sponsorship-family',
  templateUrl: './sponsorship-family.component.html',
  styleUrls: ['./sponsorship-family.component.scss']
})
export class SponsorshipFamilyComponent {
  SearchCountryField = SearchCountryField;
  selectedC = CountryISO.Senegal;
  contactFirstName: string = '';
  contactLastName: string = '';
  contactPhone: string = '';
  numberOfBeneficiaries: number = 0;

  constructor(private dataService: DataService, private router: Router) { }

  proceedToPayment(): void {
    this.router.navigate(['/payment']);
  }

  selectPhone(event: any){
    if(this.contactPhone) this.contactPhone = event.e164Number;
  }
  onSubmit() {
    const sponsorshipData = {
      nbBeneficiaries: this.numberOfBeneficiaries,
      hasContactPerson: true,
      contactFirstName: this.contactFirstName,
      contactLastName: this.contactLastName,
      contactPhone: this.contactPhone,
      beneficiaries: [] 
    };

    const family: Family = {
      status: true,
      name: `${this.contactFirstName} ${this.contactLastName}`,
      members: this.numberOfBeneficiaries,
      beneficiaries: sponsorshipData.beneficiaries 
    };

    this.dataService.setSponsorshipData({ ...sponsorshipData, family });
    console.log({ ...sponsorshipData, family });
    this.router.navigate(['/choice-mutual']);
  }
}
