import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-insurance-status-popup',
  templateUrl: './insurance-status-popup.component.html',
  styleUrls: ['./insurance-status-popup.component.scss']
})
export class InsuranceStatusPopupComponent {
  isPopupVisible: boolean = false;

  openPopup() {
    this.isPopupVisible = true;
  }

  closePopup() {
    this.isPopupVisible = false;
  }

  @Output() close = new EventEmitter<void>();

  constructor(private router: Router) {}

  navigateToMembership(): void {
    this.router.navigate(['/membership']);
    this.close.emit();
  }

  navigateToLinkCode(): void {
    this.router.navigate(['/link-code']); // Adjust this to your actual route
    this.close.emit();
  }
}
