<app-navbar/>
<div class="hero">
    <app-presenter/>
    <app-about/>
    <app-annuairelist/>
    <app-details-inscription/>
    <app-download-page/>
    <app-partenaires/>
    <app-footer/>
</div>


