import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-detail-sponshorship',
  templateUrl: './payment-detail-sponshorship.component.html',
  styleUrls: ['./payment-detail-sponshorship.component.scss']
})
export class PaymentDetailSponshorshipComponent {

  selectedPaymentMethod: string = '';
 
  paymentMethods: { name: string, image: string }[] = [
    { name: 'Wave', image: '../../../assets/images/wave.png' },
    { name: 'Orange Money', image: 'assets/images/orange-money.png' },
    { name: 'Free Money', image: 'assets/images/free-money.png' },
    { name: 'Autres', image: 'assets/images/autre.png' },
  ];

  constructor(
    
  ) { }

  ngOnInit(): void {
  
  }

  selectPaymentMethod(method: string): void {
    this.selectedPaymentMethod = method;
  }

  getSelectedPaymentImage(): string {
    const method = this.paymentMethods.find(m => m.name === this.selectedPaymentMethod);
    return method ? method.image : '';
  }

}
