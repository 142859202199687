import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Beneficiary } from '../models/sponsorship.model';
import { environment } from '~/environments/environment';
import { Endpoint } from '~/commons/constants';
import { CrudService } from '~/commons/services';
import { ApiResponse } from '~/commons/entities';


@Injectable({
  providedIn: 'root'
})
export class BeneficiaryServiceService extends CrudService<Beneficiary> {
  protected override api = environment.apiBenef;
  protected override endpoint = Endpoint.BENEFICIARIES;

  constructor(protected override httpClient: HttpClient) {
    super(httpClient);
  }
}

