import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '~/app/sponsorship/data.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent {

  transactionData: any;

  constructor(private dataService: DataService, private router: Router) {}

  ngOnInit(): void {
    this.transactionData = this.dataService.getTransactionData();
    if (!this.transactionData) {
      this.router.navigate(['/']);
    }
  }

  goToHome(): void {
    this.router.navigate(['/']);
  }

}
