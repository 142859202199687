import { Component } from '@angular/core';

import { ContactService } from './services/contact-service.service';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  token: string|undefined;
  private ngUnsubscribe = new Subject();

	public contactDetails:any = {
    "firstName": "",
    "lastName": "",
    "email": "",
    "phone": "",
    "message": ""
  };
  constructor(
    private contactService: ContactService,
    private toastr: ToastrService
  ) { 
    this.token = undefined;
  }

  onSubmit(isValid: any){
		if (isValid){
			// console.log('submit', this.contactDetails);
      var doct = {
        "firstName": this.contactDetails?.firstName,
        "lastName": this.contactDetails?.lastName,
        "email": this.contactDetails?.email,
        "phone": this.contactDetails?.phone,
        "message": this.contactDetails?.message
      }
      this.contactService.sendMail(doct).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        (res:any) => {
          console.log('haa',res)
            // window.location.href = transactionResponse.launchUrl;
            // this.toastr.success("Message envoyé avec succès");
            this.toastr.success(res.message);
            this.contactDetails = {
              "firstName": "",
              "lastName": "",
              "email": "",
              "phone": "",
              "message": ""
            };
        },
        err => {
          this.toastr.error("Erreur est survenue lors de l'envoi du message. Veuillez réessayer plus tard.");
        }
      );
    }
  }
 
}
