<div id="kt_app_content" class="app-content  flex-column-fluid bg-transparent ">

    <!--begin::Card-->
    <div class="card">
        <!--begin::Card header-->
        <div class="d-none card-header border-0 pt-0">

        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body d-flex justify-content-center w-100 flex-column pt-0 mt-10">

            <!--begin::Table-->

                

                    <div class="d-flex flex-row justify-content-between text-primary mt-10">
                        <div class="d-flex flex-column">
                            <p><strong>Région:</strong> <span>------------------</span> </p>
                            <p><strong>Mutuelle:</strong> <span>----------------</span> </p>
                        </div>
                        <div class="d-flex flex-column">
                            <p><strong>Département:</strong><span>----------------</span> </p>
                            <p><strong>Cotisation:</strong> <span> ------------ FCFA</span></p>
                        </div>
                        <div class="d-flex flex-column">
                            <p><strong>Nombre de bénéficiaire:</strong> 23 </p>
                            
                        </div>

                    </div>
                    <hr>
                    <div class="d-flex flex-row  mt-3">
                        <div class="d-flex flex-column benef">
                            <p><strong>Nom du bénéficiaire :</strong> <span class="text-primary"> Nom et prénom:</span> </p>
                            <p><strong>Contac:</strong>  <span class="text-primary">+221 77 123 45 67</span></p>
                        </div>

                        
                        

                    </div>

                    <hr>

                    <h2 class="text-primary">Choisissez un moyen de paiement</h2>
                    <div class="payment-methods row ">
                        <div *ngFor="let method of paymentMethods" (click)="selectPaymentMethod(method.name)"
                            class="col-12 col-md-3 p-2">
                            <div [ngClass]="{'payment-card': true, 'selected-card': selectedPaymentMethod === method.name}"
                                class="card h-100">
                                <img [src]="method.image" alt="{{ method.name }}" class="card-img-top">
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div class="selected-payment-method" *ngIf="selectedPaymentMethod">
                        <h4 class="text-primary text-center">
                            Paiement sécurisé avec
                            <img [src]="getSelectedPaymentImage()" alt="{{ selectedPaymentMethod }}"
                                style="width: 70px; height: auto; vertical-align: middle;">
                        </h4>
                        <div class="form-group w-100">
                            <label for="" class="text-primary "> Telephone</label>
                            <input type="text" class="form-control" id="phone"
                                placeholder="Veuillez saisir votre numéro de téléphone" 
                                name="phone">
                        </div>
                    </div>

                    <div class="buttons d-flex justify-content-end mt-4">
                        <button class="btn btn-primary ms-8 " >Precedent</button>
                        <button class="btn btn-secondary ms-8"  [routerLink]="['/scanner']" >Suivant</button>
                    </div>
                


            

            <!--end::Table-->
        </div>
        <!--end::Card body-->
    </div>
    <!--end::Card-->


</div>