// import { Component, OnInit } from '@angular/core';
// import { Router } from '@angular/router';
// import { ToastrService } from 'ngx-toastr';

// import { PaymentMethodeService } from '~/app/modules/auth/services/payment-methode.service';
// import { DataService } from '~/app/sponsorship/data.service';
// import { PaymentService } from '~/app/sponsorship/services/payment-service.service';
// import { SponsorshipService } from '~/app/sponsorship/services/sponsorship.service';

// @Component({
//   selector: 'app-payment-cesarienne',
//   templateUrl: './payment-cesarienne.component.html',
//   styleUrls: ['./payment-cesarienne.component.scss']
// })
// export class PaymentCesarienneComponent implements OnInit {
//   sponsorshipId: number | null = null;
//   selectedType: string = '';
//   selectedParrainage: string = '';
//   contactPerson: string = '';
//   amount: number = 0;
//   selectedMutuelle: string = '';
//   selectedPaymentMethod: string = '';
//   phone: string = '';
//   nbBeneficiaries: number = 0;
//   paymentMethodId: number = 1; // Valeur par défaut

//   paymentMethods: { name: string, image: string, commissionRate: number, totalAmount: string, paymentMethodId: number }[] = [];

//   constructor(
//     private dataService: DataService,
//     private paymentService: PaymentService,
//     private router: Router,
//     private toastr: ToastrService,
//     private sponshorService: SponsorshipService,
//     private paymentMethodeService: PaymentMethodeService,
//   ) { }

//   ngOnInit(): void {
//     const sponsorshipData = this.dataService.getSponsorshipData();
//     this.selectedType = sponsorshipData.type || '';
//     this.selectedParrainage = sponsorshipData.parrainage || '';
//     this.contactPerson = sponsorshipData.hasContactPerson 
//       ? `${sponsorshipData.contactFirstName} ${sponsorshipData.contactLastName}` 
//       : 'N/A';
//     const paymentData = this.dataService.getPaymentData();
//     this.amount = paymentData.total || 0;
//     const mutualData = this.dataService.getMutualData();
//     this.selectedMutuelle = mutualData.selectedMutuelle || '';

//     this.sponsorshipId = this.sponshorService.getSponsorshipId();
//     this.sponshorService.getAllSponsorships().subscribe(sponsorships => {
//       const currentSponsorship = sponsorships.find(s => s.id === this.sponsorshipId);
//       if (currentSponsorship) {
//         this.nbBeneficiaries = currentSponsorship.nbBeneficiaries || 0;
//       }
//     });

//     this.loadPaymentMethods();
//   }

//   loadPaymentMethods() {
//     this.paymentMethodeService.findAll(0, 10, '', true).subscribe(
//       response => {
//         console.log('Response from payment methods endpoint:', response); 
//         this.paymentMethods = response.data.map(paymentMethod => ({
//           name: paymentMethod.operateur.nom,
//           image: paymentMethod.operateur.urlLogo,
//           commissionRate: paymentMethod.taux,
//           totalAmount: '0000 Cfa',
//           paymentMethodId: paymentMethod.id
//         }));
//       },
//       error => {
//         this.toastr.error('Erreur lors du chargement des méthodes de paiement');
//       }
//     );
//   }

//   selectPaymentMethod(method: string): void {
//     this.selectedPaymentMethod = method;
//     const selectedMethod = this.paymentMethods.find(m => m.name === method);
//     if (selectedMethod) {
//       this.paymentMethodId = selectedMethod.paymentMethodId;
//     }
//   }

//   getSelectedPaymentImage(): string {
//     const method = this.paymentMethods.find(m => m.name === this.selectedPaymentMethod);
//     return method ? method.image : '';
//   }

//   validatePayment(): void {
//     if (!this.phone) {
//       this.toastr.error('Veuillez remplir tous les champs');
//       return;
//     }

//     const sponsorshipData = this.dataService.getSponsorshipData();

//     const transactionDetails = {
//       id: this.paymentMethodId,
//       sponsorship: {
//         id: this.sponsorshipId
//       },
//       paymentMethodId: this.paymentMethodId
//     };

//     this.paymentService.initiateTransaction(transactionDetails).subscribe(
//       transactionResponse => {
//         const transactionData = {
//           id: transactionResponse.id,
//           createdAt: new Date().toISOString(),
//           status: true,
//           amount: this.amount,
//           fee: transactionResponse.fee,
//           amountTotal: transactionResponse.amountTotal,
//           recipientPhoneNumber: this.phone,
//           transactionStatus: transactionResponse.status,
//           paymentMethodId: transactionDetails.paymentMethodId,
//           paymentMethod: {
//             id: transactionDetails.paymentMethodId,
//             extendedInfo: 'string',
//             internalInfo: 'string'
//           },
//           transactionType: 'WAVE',
//           extTransactionId: transactionResponse.extTransactionId
//         };

//         this.dataService.setTransactionData(transactionData);
//         if (transactionResponse.launchUrl) {
//           this.toastr.success("Payment initié avec succès");
//           window.location.href = transactionResponse.launchUrl;
//         } else {
//           // this.toastr.success("Payment initié avec succès");
//           // this.router.navigate(['/scanner']);
//         }

//         // this.router.navigate(['/scanner']);
//         // this.toastr.success("Payment initié avec succès");
//       },
//       error => {
//         this.toastr.error("Erreur lors de l'initiation de la transaction. Veuillez réessayer.");
//       }
//     );
//   }

//   cancelPayment(): void {
//     this.router.navigate(['/previous-route']);
//   }
// }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { PaymentMethodeService } from '~/app/modules/auth/services/payment-methode.service';
import { DataService } from '~/app/sponsorship/data.service';
import { PaymentService } from '~/app/sponsorship/services/payment-service.service';
import { SponsorshipService } from '~/app/sponsorship/services/sponsorship.service';

@Component({
  selector: 'app-payment-cesarienne',
  templateUrl: './payment-cesarienne.component.html',
  styleUrls: ['./payment-cesarienne.component.scss']
})
export class PaymentCesarienneComponent implements OnInit {
  sponsorshipId: number | null = null;
  selectedType: string = '';
  selectedParrainage: string = '';
  contactPerson: string = '';
  amount: number = 0;
  selectedMutuelle: string = '';
  selectedPaymentMethod: string = '';
  phone: string = '';
  nbBeneficiaries: number = 0;
  paymentMethodId: number = 1; // Valeur par défaut

  paymentMethods: { name: string, image: string, commissionRate: number, totalAmount: string, paymentMethodId: number, categorie: string }[] = [];

  // Card details properties
  cardNumber: string = '';
  cardExpireYear: string = '';
  cardExpireMonth: string = '';
  cvv: string = '';
  cardHolderName: string = '';
  clientFirstname: string = '';
  clientLastname: string = '';

  constructor(
    private dataService: DataService,
    private paymentService: PaymentService,
    private router: Router,
    private toastr: ToastrService,
    private sponshorService: SponsorshipService,
    private paymentMethodeService: PaymentMethodeService,
  ) { }

  ngOnInit(): void {
    const sponsorshipData = this.dataService.getSponsorshipData();
    this.selectedType = sponsorshipData.type || '';
    this.selectedParrainage = sponsorshipData.parrainage || '';
    this.contactPerson = sponsorshipData.hasContactPerson 
      ? `${sponsorshipData.contactFirstName} ${sponsorshipData.contactLastName}` 
      : 'N/A';
    const paymentData = this.dataService.getPaymentData();
    this.amount = paymentData.total || 0;
    const mutualData = this.dataService.getMutualData();
    this.selectedMutuelle = mutualData.selectedMutuelle || '';

    this.sponsorshipId = this.sponshorService.getSponsorshipId();
    this.sponshorService.getAllSponsorships().subscribe(sponsorships => {
      const currentSponsorship = sponsorships.find(s => s.id === this.sponsorshipId);
      if (currentSponsorship) {
        this.nbBeneficiaries = currentSponsorship.nbBeneficiaries || 0;
      }
    });

    this.loadPaymentMethods();
  }

  loadPaymentMethods() {
    this.paymentMethodeService.findAll(0, 10, '', true).subscribe(
      response => {
        console.log('Response from payment methods endpoint:', response); 
        this.paymentMethods = response.data.map(paymentMethod => ({
          name: paymentMethod.operateur.nom,
          image: paymentMethod.operateur.urlLogo,
          commissionRate: paymentMethod.taux,
          totalAmount: '0000 Cfa',
          paymentMethodId: paymentMethod.id,
          categorie: paymentMethod.categorie
        }));
      },
      error => {
        this.toastr.error('Erreur lors du chargement des méthodes de paiement');
      }
    );
  }

  selectPaymentMethod(method: string): void {
    this.selectedPaymentMethod = method;
    const selectedMethod = this.paymentMethods.find(m => m.name === method);
    if (selectedMethod) {
      this.paymentMethodId = selectedMethod.paymentMethodId;
    }
  }

  getSelectedPaymentImage(): string {
    const method = this.paymentMethods.find(m => m.name === this.selectedPaymentMethod);
    return method ? method.image : '';
  }

  showCardFields(): boolean {
    const selectedMethod = this.paymentMethods.find(m => m.name === this.selectedPaymentMethod);
    return selectedMethod ? selectedMethod.categorie === 'card' : false;
  }

  validatePayment(): void {
    if (!this.phone) {
      this.toastr.error('Veuillez remplir tous les champs');
      return;
    }

    const sponsorshipData = this.dataService.getSponsorshipData();
    const selectedMethod = this.paymentMethods.find(m => m.name === this.selectedPaymentMethod);

    const transactionDetails: any = {
      id: this.paymentMethodId,
      sponsorship: {
        id: this.sponsorshipId
      },
      paymentMethodId: this.paymentMethodId
    };

    if (selectedMethod?.categorie === 'card') {
      transactionDetails.cardNumber = this.cardNumber;
      transactionDetails.cardExpireYear = this.cardExpireYear;
      transactionDetails.cardExpireMonth = this.cardExpireMonth;
      transactionDetails.cvv = this.cvv;
      transactionDetails.cardHolderName = this.cardHolderName;
      transactionDetails.clientFirstname = this.clientFirstname;
      transactionDetails.clientLastname = this.clientLastname;
    }

    this.paymentService.initiateTransaction(transactionDetails).subscribe(
      transactionResponse => {
        const transactionData = {
          id: transactionResponse.id,
          createdAt: new Date().toISOString(),
          status: true,
          amount: this.amount,
          fee: transactionResponse.fee,
          amountTotal: transactionResponse.amountTotal,
          recipientPhoneNumber: this.phone,
          transactionStatus: transactionResponse.status,
          paymentMethodId: transactionDetails.paymentMethodId,
          paymentMethod: {
            id: transactionDetails.paymentMethodId,
            extendedInfo: 'string',
            internalInfo: 'string'
          },
          transactionType: selectedMethod?.categorie === 'card' ? 'CARD' : 'WAVE',
          extTransactionId: transactionResponse.extTransactionId
        };

        this.dataService.setTransactionData(transactionData);
        if (transactionResponse.launchUrl) {
          this.toastr.success("Payment initié avec succès");
          window.location.href = transactionResponse.launchUrl;
        } else {
          this.toastr.success("Payment initié avec succès");
          this.router.navigate(['/scanner']);
        }
      },
      error => {
        this.toastr.error("Erreur lors de l'initiation de la transaction. Veuillez réessayer.");
      }
    );
  }

  cancelPayment(): void {
    this.router.navigate(['/previous-route']);
  }
}
