import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of, ReplaySubject, shareReplay, tap } from "rxjs";
import { CurrentUser } from "../models/currentUser.model";
import { HttpClient } from "@angular/common/http";
import { UserCredentials } from "../models/userCredentials.model";
import { Endpoint, Global } from "../../../../commons/constants";
import { environment } from "../../../../environments/environment";
import { UserRegister } from "../models/userRegister.model";
import { LoginResponse } from "../models/loginResponse.model";
import { ChangePassword } from "../models/changePassword.model";
import { CheckOtp } from '../models/check-otp.model';
import { ForgotPasswordRequest } from '../models/ForgotPassword.model';
import { throwError } from 'rxjs';
import { UpdatePhone } from '../models/UpdatePhone.model';
import { UpdateEmail } from '../models/UpdateEmail.model';
import { ChangePwd } from '../models/ChangePwd.model';
import { OldEmail } from '../models/OldEmail.model';
import { UpdateUser } from '../models/UpdateUser.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private registeredUserData: UserRegister | null = null;
  private userFirstNameSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private currentUser: CurrentUser | null = null;
  private _currentUser = new BehaviorSubject<CurrentUser | null>(null) ;
  private currentUser$: Observable<CurrentUser | null> = this._currentUser.asObservable();
  private _currentUser$ = new ReplaySubject<CurrentUser | null>(1);
  private _isAuth = new BehaviorSubject<boolean>(false);
  private isAuth$ = this._isAuth.asObservable();
  
  
  constructor(private http: HttpClient) {
  }

  /**
   * 
   * @param currentUser 
   */
  setCurrentUser(currentUser: CurrentUser) {
    this._currentUser.next(currentUser);
    this.currentUser = currentUser;
    this._isAuth.next(true);
  }
  
  /**
   * 
   * @returns an observable of type CurrentUser
   */
  getCurrentUser(): Observable<CurrentUser | null> {
    return this.currentUser$;
  }
  
  /**
   * 
   * @param data object of type UserCredentials
   * @returns a LoginResponse
   */
  login(data: UserCredentials):Observable<LoginResponse>{
    
    return this.http.post<LoginResponse>(`${environment.apiAuth}${Endpoint.LOGIN}`, data);
  }
  
  /**
   * 
   * @param data object of type UserRegister
   * @returns 
   */
  register(data: UserRegister){
    return this.http.post<any>(`${environment.apiBenef}${Endpoint.SIGNUP}`, data);
  }
  
  /**
   * 
   * @param data 
   * @returns 
   */
  forgotPwd(data: ForgotPasswordRequest){
    return this.http.post<Response>(`${environment.apiAuth}/${Endpoint.FORGGOTPWD}`, data);
  }
  
  /**
   * 
   * @param data object of type ChangePassword
   * @returns 
   */
  ChangePwd(data: ChangePassword){
    return this.http.post<Response>(`${environment.apiAuth}${Endpoint.CHANGEPWD}`, data);
  }

  
  checkOTP(otpData: CheckOtp): Observable<any> {
    return this.http.post<Response>(`${environment.apiAuth}${Endpoint.CHECKOTP}`, otpData);
  }
  getUserFirstName(): Observable<string> {
    return this.userFirstNameSubject.asObservable();
  }

  // Méthode pour définir le prénom de l'utilisateur
  setUserFirstName(firstName: string): void {
    this.userFirstNameSubject.next(firstName);
  }


  //Upadte phonenumber 
  updatePhoneNumber(updatePhoneData: UpdatePhone): Observable<any> {
    return this.http.patch<any>(`${environment.apiAuth}${Endpoint.UPDATE_PHONE}`, updatePhoneData);
  }

  //Upate Email
  UpdateEmail (UpdateEmailData: UpdateEmail) : Observable <any> {
    return this.http.patch (`${environment.apiAuth}${Endpoint.UPDATE_EMAIL}` , UpdateEmailData);
  }

  //Update firstName et lastName 
  updateUserName(updateUserData: UpdateUser): Observable<any> {
    return this.http.put(`${environment.apiAuth}${Endpoint.UPDATE_USER_NAME}`, updateUserData);
  }

  //OtptoOldEmail
  SendOTPToOldEmail (SendOtpData : OldEmail ) : Observable<any> {
    return this.http.post (`${environment.apiAuth}${Endpoint.SENDOTP}` , SendOtpData);
  }

 /**
   * 
   * @param data object of type ChangePassword
   * @returns 
   */
 ChangePassword(data: ChangePwd){
  return this.http.put(`${environment.apiAuth}${Endpoint.CHANGEPASS}`, data);
}
  
  
  
  /**
   * 
   * @returns the jwt token of the current user
   */
  getJwtToken(): string | null{
    return localStorage.getItem(Global.JWT_TOKEN);
  }

  /** 
   * @param force for forcing the get of /me endpoint or not
   * returning the current user observable by getting it from the /me if null
  */
  identity(force?: boolean): Observable<CurrentUser | null> {
    if (!this.currentUser || force) {
      this.currentUser$ = this.http.get<CurrentUser>(`${environment.apiAuth}/auth/me`).pipe(
        tap((currentUser: CurrentUser) => {
          this.setCurrentUser(currentUser);

          // this.navigateToStoredUrl();
        }),
        shareReplay()
      );
    }
    return this.currentUser$.pipe(catchError(() => of(null)));
  }

  /**
   * 
   * @param data object of type LoginResponse from the login
   * @description store the token in the localstorage and the current user in an observable currentUser$
   */
  setAuthenticate(data: LoginResponse){
    localStorage.setItem(Global.JWT_TOKEN, data.token);
    this.setCurrentUser(data.user);
  }
  
  /**
   * 
   * @returns whether the user is authenticated or not
   */
  isAuthenticated():boolean{
    let isauth:boolean = false;
    this.getCurrentUser()?.subscribe(user=> {
      isauth = user !== null;
    })
    
    return this.currentUser !== null;
  }

  /**
   * 
   * @returns whether the user is authenticated or not
   */
  isAuthenticated$():Observable<boolean>{
    let isauth:boolean = false;
    this.getCurrentUser()?.subscribe(user=> {
      isauth = user !== null;
    })
    
    return this.isAuth$;
  }

  //update picture 
  updateProfilePicture(payload: { content: string }): Observable<any> {
    return this.http.put(`${environment.apiAuth}${Endpoint.UPDATE_PROFILE_PICTURE}`, payload, {
      //headers: { 'Content-Type': 'application/json' }
    });
  }
  
  /**
   * @description log the user out by deleting the token and the user's infos
   */
  logout(){
    localStorage.removeItem(Global.JWT_TOKEN);
    this._currentUser.next(null);
    // this.currentUser$ = null;
    this.currentUser = null;
    this._isAuth.next(false);
  }

  /**
   * 
   * @param authorities an array of permission or a permission
   * @returns if the current user have the authorities given in paramater
   */
  hasAnyAuthority(authorities: string[] | string): boolean {
   // if (!this.isAuthenticated()) {
      return false;
    }
    //if (!Array.isArray(authorities)) {
     // authorities = [authorities];
    }
    //return this.currentUser!.permissions.some((authority: string) => authorities.includes(authority)) || this.currentUser!.roles.some((authority: string) => authorities.includes(authority));;
    
  //}
//}
