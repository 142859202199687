<app-navbar></app-navbar>
<div class="overlay">
     <div class="text-center main">
        <h3 class="text-secondary">title</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod sint pariatur culpa nesciunt enim optio iusto eius. Aut est blanditiis numquam. Accusamus ea harum laboriosam adipisci est fugit beatae. Exercitationem!</p>
        
        <div class="d-flex justify-content-center align-items-center">
            <app-initiative-detail [initiativeId]="initiativeId"></app-initiative-detail>
        </div>
   
</div>

<app-footer></app-footer>
